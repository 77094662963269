import styled from 'styled-components';
import EnterInputCursor from 'src/assets/images/enter-input-cursor.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.white.main};
  width: 670px;
  min-height: 433px;
  border-radius: 6px;
  margin: ${props => props.theme.margins.extraLarge} auto 0;

  .authContentWrapper {
    width: 360px;

    .authLogo {
      margin-left: calc(50% - 50px);
      margin-top: ${ p => p.theme.margins.extraLarge };
    }
  }

  .phoneTextWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 29px;
  }

  .sendText {
    line-height: 18px;
    margin-right: 7px;
  }

  .resendCodeWrapper {
    display: flex;
    justify-content: center;
  }

  .resendCode {
    cursor: pointer;
    color: #3b7b9c;
  }
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  
  .greenSpinner {
    width: 57px;
    height: 57px;
    margin-top: 32px;
    circle {
      &.path {
        stroke: ${ p => p.theme.colors.green.main };
      }
    }
    
    div {
      width: 57px;
      height: 57px;
      border: 3px solid;
      border-color: ${props => props.theme.colors.green.main} transparent transparent transparent
    }
  }
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 27px;
  position: relative;

  .container {
    height: auto;
    width: auto;
  }

  .character {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 4px;
    margin: 0 ${props => props.theme.margins.small};
    width: 40px;
    height: 40px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #242a38;
  }

  .error-border {
    border: none;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.red.main};
  }

  .active {
    border: 1px solid #158fff;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(21, 143, 255, 0.3);
  }

  .character--selected {
    background-image: url(${EnterInputCursor});
    background-repeat: no-repeat;
    background-position: center;
  }

  .displayCursor {
    background-image: none;
  }

  .error {
    position: absolute;
    top: 45px;
    left: 50%;
    opacity: 0;
    line-height: 10px;
    transform: translatex(-50%);
  }

  .errorTextTrue {
    opacity: 1;
  }  
`;


