
// ----------------------------- Venue ID  -----------------------------
export const SET_SELECTED_VENUE_ID = 'SET_SELECTED_VENUE_ID';
export type SetSelectedVenueIdActionType = {
    type: typeof SET_SELECTED_VENUE_ID;
    payload: number;
};

export type SetSelectedVenueIdContract = ( payload: number ) => SetSelectedVenueIdActionType;

// ----------------------------- Venue Event ID  -----------------------------
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID';
export type SetSelectedEventIdActionType = {
    type: typeof SET_SELECTED_EVENT_ID;
    payload: number;
};

export type  SetSelectedEventIdContract = ( payload: number ) => SetSelectedEventIdActionType;


export type UploadDataActionTypes = 
SetSelectedVenueIdActionType         |
SetSelectedEventIdActionType        
