import API, { AUTH_URL } from 'src/Logic/API/API';
import { VerificationCodeParamsType } from 'src/Logic/API/Verification/SendVerificationCodePost/types';

export const sendVerificationCodePost = ({
  requestId,
  verificationCode,
}: VerificationCodeParamsType) => {
  return API.post(`api/v1/verification/phoneNumber/code`, {
    requestId,
    verificationCode,
  },
  {
    baseURL: AUTH_URL,
  },
  );
};
