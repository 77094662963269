import { AllActionsType, SignInErrorType } from '../actions/errorType/types';

export type InitialStateType = typeof initialState;

const initialState = {
  error: {} as SignInErrorType,
  requestsLimitError: false
};

export const errorTypeReducer = (
  state: InitialStateType = initialState,
  action: AllActionsType,
) => {
  switch (action.type) {
    case 'SET_SIGN_IN_ERROR': {
      const { errorType, statusCode } = action.payload;
      return {
        ...state,
        error: {
          errorType,
          statusCode,
        },
      };
    }

    case 'SET_REQUESTS_LIMIT_ERROR': {
      const { requestsLimitError } = action.payload;
      return {
        ...state,
        requestsLimitError,
      };
    }

    default:
      return state;
  }
};
