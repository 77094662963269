import { CircleSpinner } from 'src/elements/spinner/circleSpinner';
import { SpinnerContainer } from 'src/components/loadingSpinner/styles';
import { ActivitiesSpinnerPropsType } from 'src/components/loadingSpinner/types';

export const LoadingSpinner: React.FC<ActivitiesSpinnerPropsType> = ({ loadingText, headerText, style, className }) => {
    return (
        <SpinnerContainer style = { style } className = { className } >
            { headerText && <div className={'titleName'}>{headerText}</div> }
            { loadingText && <span className={'standart-text loadingText'}>{loadingText}</span> }
                <CircleSpinner className='big'/>
        </SpinnerContainer>
    );
};

