import { stopPhotoUploading as stopPhotoUploadingRequest } from "src/Logic/API/PhotoUpload/stopUploading"
import { useSOFMutation } from "../useSOFMutation";
import { useUploadData } from "../useUploadData";

type Props = {
    onSuccess?: () => void,
} | undefined

export const useStopUploadMutation = ( props?: Props ) => {
    
    const { venueEventId } = useUploadData(); 
    const { mutate, isLoading} = useSOFMutation( stopPhotoUploadingRequest, { onSuccess: props?.onSuccess } );

    const stopPhotoUploading = () => {
        venueEventId && mutate({ venueEventId });
    }

    return {
        stopPhotoUploading,
        isStoping: isLoading
    }
}