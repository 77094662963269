// Core
import { ChangeEvent, useEffect, useRef, useState } from 'react';

// Styles
import { InputWrapper } from './styles';

// Svg
import Delete from 'src/assets/images/delete.svg';

type Props = {
  value?: string | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string | undefined;
  defaultValue?: string | undefined;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  error?: boolean;
  errorText?: string;
  required?: boolean;
  onBlur?: () => void;
  type?: 'text' | 'password' | 'number';
  blurOnEnter?: boolean;
  limitCharacters?: number;
  clearErrors?: (error: string | undefined) => void;
  name?: string;
  // onkey?: (e: any) => void
};

export const Input = ({
  onChange,
  placeholder,
  value,
  defaultValue,
  disabled,
  className,
  style,
  onKeyDown,
  error,
  errorText,
  type,
  required,
  blurOnEnter,
  onBlur,
  limitCharacters,
  clearErrors,
  name,
  ...rest
}: Props) => {
  const [replaced, setReplaced] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const crossImgRef = useRef<HTMLImageElement>(null);

  const focusInput = () => {
    if (inputRef.current && !disabled) {
      setFocused(true);
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOnElementClick);
    return () => {
      document.removeEventListener('click', handleOnElementClick);
    };
  }, [onBlur, disabled]);

  useEffect(() => {
    if (value !== inputValue) setInputValue(valueWithLimit(value));

    if (value !== undefined && value !== null && String(value).trim() !== '') setReplaced(true);
    else setReplaced(false);
  }, [value]);

  const handleBlur = () => {
    setFocused(false);

    if (inputRef.current && !disabled) {
      inputRef.current.blur();
    }

    if (focused && onBlur) onBlur();
  };

  const handleOnElementClick = (e: any) => {
    if (e.target === crossImgRef.current) handleClear();
    else if (wrapperRef.current && !wrapperRef.current.contains(e.target) && !disabled) {
      handleBlur();
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (clearErrors && errorText) {
      clearErrors(name);
    }
    if (e.target.value && e.target.value.trim() !== '') {
      setReplaced(true);
    } else {
      setReplaced(false);
    }

    if (type === 'number') e.target.value = e.target.value.replace(/[^\d.]/g, '');

    setInputValue(valueWithLimit(e.target.value));

    if (onChange) onChange(e);
  };

  const handleHover = (value: boolean) => {
    setHovered(value);
  };

  const handleClear = () => {
    if (inputRef.current) {
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )?.set;
      nativeInputValueSetter?.call(inputRef.current, '');

      var ev = new Event('input', { bubbles: true });
      inputRef.current.dispatchEvent(ev);
    }
    focusInput();
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    blurOnEnter && e.key === 'Enter' && handleBlur();
    onKeyDown && onKeyDown(e);
    // onkey && onkey({handleBlur});
  };

  const valueWithLimit = (value: string | undefined) => {
    if (value && limitCharacters && value.length > limitCharacters) {
      return(value.slice(0, limitCharacters));
    } else {
      return value;
    }
  };

  return (
    <InputWrapper
      onClick={focusInput}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      // onFocus = { focusInput }
      ref={wrapperRef}
      className={`${focused ? ' focused' : ''}${disabled ? ' disabled' : ''} 
            ${required || error ? ' boldBorder' : ''} ${className ? ` ${className}` : ''}
            ${error ? ' error' : ''}${required ? ' required' : ''}`}
      style={style}
    >
      <span className={`placeHolder${replaced ? ' replaced' : ''}`}>{placeholder}</span>
      {!disabled && inputValue && inputValue !== '' && (hovered || focused) ? (
        <img ref={crossImgRef} src={Delete} alt="delete" />
      ) : null}
      <input
        onKeyDown={keyDownHandler}
        ref={inputRef}
        type={type === 'password' ? type : 'text'}
        value={
          inputValue !== undefined && inputValue !== null
            ? inputValue
            : defaultValue !== undefined && defaultValue !== null
            ? defaultValue
            : ''
        }
        disabled={disabled}
        onChange={onInputChange}
        {...rest}
      />
      <div className="errorText">{errorText}</div>
    </InputWrapper>
  );
};
