import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: Infinity,
            keepPreviousData: true,
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

export enum QueryKeys {
    AuthTimezones = 'AuthTimezones',
    Venues = 'Venues',
    VenueEvents = 'VenueEvents',
    UploadedFiles = 'UploadedFiles',
    ProfileData = 'ProfileData',
    SignOut = 'SignOut',
}
