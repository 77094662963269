import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import DownArrow from 'src/assets/images/down-arrow.svg';
import {
    Container,
    DropDownHeader,
    DropDownList,
    DropDownListContainer,
    DropDownListContainerWrapper,
    Input,
    ListItem,
    Main,
    Placeholder,
    SelectInputContainer,
} from './style';
import { CountryType } from 'src/utils/countries/countries';
import { prepareNumberToView, replaceNonDigits } from './helpers';

type PhoneSelectInputPropsType = {
    countries: CountryType[];
    selectedCountry: CountryType;
    inputValue: string;
    phoneCode: string;
    changeInputValue: (inputValue: string) => void;
    selectCountry: (country: CountryType) => void;
    changePhoneCode: (phoneCode: string) => void;
    className?: string;
    maxLength?: number,
};

export const PhoneSelectInput: React.FC<PhoneSelectInputPropsType> = ({
    countries,
    selectedCountry,
    inputValue,
    phoneCode,
    changeInputValue,
    selectCountry,
    changePhoneCode,
    className,
    maxLength,
}) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ focused, setFocused ] = useState( false );
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current &&
        inputRef.current.setAttribute('qa-data-id', 'phone-number-filed')
    }, [ inputRef ]);

    const useOutsideAlerter = (ref: any) => {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const onOptionClicked = (country: CountryType) => () => {
        selectCountry(country);
        changePhoneCode(country.phone);
        changeInputValue(country.phone);
        setIsOpen(false);
    };

    const toggling = () => {
        setIsOpen(!isOpen);
    };

    

    const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.slice(0, phoneCode.length) !== phoneCode) {
            return;
        }

        if (e.currentTarget.value.length >= phoneCode.length) {
            const newValue = replaceNonDigits(e.currentTarget.value).slice(0, maxLength );
            changeInputValue(newValue);
        }
    };

    return (
        <Main>
            <Container >
                <SelectInputContainer className={`${ focused ? 'focused' : ''}${` ${ className }`}`}>
                    <DropDownHeader onClick={toggling}>
                        <img src={selectedCountry.flagURL} alt={selectedCountry.code} className={'flagIcon'} />
                        <img src={DownArrow} alt={selectedCountry.code} className={'downArrow'} />
                    </DropDownHeader>

                    <Input 
                        ref = { inputRef }
                        qa-data-id = 'phone-number-filed'
                        value={prepareNumberToView( inputValue, phoneCode )} 
                        onChange={changeInput}
                        onFocus = {() => { setFocused( true )}}
                        onBlur = {() => { setFocused( false )}}  
                    />
                    <Placeholder>Mobile Number</Placeholder>
                </SelectInputContainer>
                <DropDownListContainerWrapper>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList ref={wrapperRef}>
                            {countries.map((country: CountryType) => (
                            <ListItem onClick={onOptionClicked(country)} key={country.id}>
                                <img src={country.flagURL} alt={country.code} className={'flagIcon'} />
                                <span className={'countryData'}>
                                {country.name} {country.phone}
                                </span>
                            </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
                </DropDownListContainerWrapper>
            </Container>
        </Main>
    );
};
