import moment from 'moment-timezone';

const PICKER_DISPLAYED_DATES_COUNT = 42;

export const prepareDaysToDisplay = (date:  moment.Moment) => {

    let dateForManipulations = date.clone();
    dateForManipulations = dateForManipulations.startOf('month');
    const firstDayIndex =  dateForManipulations.day();
    dateForManipulations.add(-firstDayIndex, 'days');

    const arrayOfDays = [];

    for(let i = 0; i < PICKER_DISPLAYED_DATES_COUNT; i++) {
        arrayOfDays[i] = dateForManipulations.clone();
        dateForManipulations.add(1, 'day');
    }

    return arrayOfDays;
}