import { useEffect, useState } from "react";
import { ConfirmModal } from "src/components/modals/confirmModal";
import { FileAccepter } from "./fileAccepter";
import { ALLOWED_EXTENSION, FileStatuses } from "./types";
import { UploadDashboard } from "./uploadDashboard/UploadDashboard";
import * as s from './styles';
import { calculateProgress } from "./helpers/inex";
import { Link, useNavigate } from "react-router-dom";
import { SELECT_VENUE_EVENT } from "src/components/header/constants";
import { useFileUploader } from "src/Logic/hooks/fileUploader/useFileUploader";
import { useUploadedMPhotosQuery } from "src/Logic/hooks/PhotoUpload/useUploadedFilesQuery";
import { useStopUploadMutation } from "src/Logic/hooks/PhotoUpload/useStopUploadMutation";
import { useQueryClient, UseQueryResult } from "react-query";
import { QueryKeys } from "src/init/reactQuery";
import { AxiosResponse } from "axios";
import { UploadedMasterPhotosResponse } from "src/Logic/API/PhotoUpload/getUploadedFiles";
import { cloneDeep } from "lodash";
import { VenueEventsResponseType } from "src/Logic/API/VenueEvents/getVenueEvents";
import { MainDateFormat } from "src/Logic/Helpers/Converters";

enum StateTypes {
    Idle = 'Idle',
    Completed = 'Completed',
    Uploading = 'Uploading',
} 

const MAX_UPLOADERS_COUNT = 20;

export const PhotoUploadPage = () => {
    
    const [ state, setState ] = useState<StateTypes>( StateTypes.Idle );
    const [ addMoreFilesModalVisible, setAddMoreFilesModalVisible  ] = useState( false );

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { uploadedFiles, venueEventId, isMasterPhotosLoading, getUploadedFiles } = useUploadedMPhotosQuery();
    const { stopPhotoUploading, isStoping } = useStopUploadMutation({ onSuccess: getUploadedFiles });
    const eventData = queryClient.getQueryData<UseQueryResult<VenueEventsResponseType>>( QueryKeys.VenueEvents )
        ?.data?.venueEvents.find(( e ) => e.id === venueEventId);

    const { files, aborted, addFiles, reuploadFailedFiles, stopUploading } = useFileUploader({ 
        maxThreads: MAX_UPLOADERS_COUNT, 
        allowedTypes: ALLOWED_EXTENSION,
        uploadedFiles,
        APIConfig: { 
            method: 'POST',
            url: `api/venueEvents/${ venueEventId }/upload/photos`,
        },
        onFileUpload: ( data ) => {
            queryClient.setQueryData<AxiosResponse<UploadedMasterPhotosResponse> | undefined >( 
                QueryKeys.UploadedFiles, 
                ( prevData ) => { 
                    if( prevData?.data ) { 
                        const newData = cloneDeep( prevData );
                        newData.data.files.push({
                            hash: String( data.hash ),
                            name: data.file.name,
                            status: FileStatuses.Uploaded,
                        })
                        return newData;
                    }
                    return prevData;
                }, 
            );
        },
        onAbort: stopPhotoUploading,
    });

    useEffect(() => {
        if( venueEventId === null )
            navigate( SELECT_VENUE_EVENT, { replace: true });
    }, [ venueEventId ]);

    useEffect(() => {
        if( files.length === 0)
            setState( StateTypes.Idle );
        else if( !aborted && !isStoping && !isMasterPhotosLoading && files.length > 0 && 
            files.filter( f => f.status === FileStatuses.Uploaded || 
            f.status === FileStatuses.Failed || f.status === FileStatuses.Stopped ).length === files.length )
                setAddMoreFilesModalVisible( true );
        else setState( StateTypes.Uploading );

    }, [ files, aborted, isMasterPhotosLoading, isStoping ]);
    
    return (
        <s.Wrapper>
            <div className = "container" >
                <span className = "title" >{ eventData?.name }, ID { eventData?.id }, &nbsp;
                    {MainDateFormat( eventData?.startTimeET )}</span>
                <FileAccepter 
                    onReupload = { reuploadFailedFiles }
                    completed = { state === StateTypes.Completed } 
                    result = {{ 
                        failed: files.filter( f => f.status === FileStatuses.Failed ).length, 
                        stopped: files.filter( f => f.status === FileStatuses.Stopped ).length,
                        uploaded: files.filter( f => f.status === FileStatuses.Uploaded ).length, 
                    }}
                    onFilesAdd = { addFiles }
                    disabled = { state === StateTypes.Completed }
                />
                { state === StateTypes.Uploading && 
                    <UploadDashboard 
                        onUploadStop = { stopUploading } 
                        progressPercents = { calculateProgress( files ) } 
                        files = { files }
                    />
                }
                { state !== StateTypes.Uploading && 
                    <Link 
                        to = { SELECT_VENUE_EVENT }
                        className = 'mtMain' 
                    >Upload photos for another event</Link> }
            </div>
            <ConfirmModal 
                onCancel = {() => { 
                    setAddMoreFilesModalVisible( false );
                    setState( StateTypes.Completed );
                }}
                onOk = {() => {
                    setAddMoreFilesModalVisible( false );
                }}
                cancelText = "No"
                confirmText = "Yes" 
                visible = { addMoreFilesModalVisible }
                title = 'Upload more photos?'
            />
        </s.Wrapper> 
    );
}
