import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: ${ p => p.theme.margins.main };
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        width: 521px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 20px; 
            font-weight: bold;
            margin-bottom: ${ p => p.theme.margins.main }; 
    
        }
    
        .tableContatiner {
            width: ${ p => p.theme.sizes.width.mainPage };
    
    
            .selectBtn {
                width: 80px;
            }
        }
    
        .message {
            margin-top: 56px;
            max-width: 240px;
            text-align: center;
        }

        a { 
            font-weight: bold;
        }
    }
`;
