import styled from "styled-components"

export const InputWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid ${ props => props.theme.colors.gray.dark};
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    height: 40px;

    &:hover {
        border: solid 1px ${ props => props.theme.colors.blue.input };
    }

    &.focused {
        box-shadow: 0 0 6px ${ props => props.theme.colors.blue.shadow };
        border: solid 1px ${ props => props.theme.colors.blue.input };
    }

    &.boldBorder {
        border-width: 2px;
        box-shadow: none;

        &:hover {
            border-width: 2px;
        }

        &.focused {
            .placeHolder {
                padding: 6px 11px 7px;
            }

            &.error {
                box-shadow: 0 0 6px ${ props => props.theme.colors.red.shadow}; 
            }
            &.required {
                box-shadow: 0 0 6px ${ props => props.theme.colors.green.shadow}; 
            }
        }

        .placeHolder {
            &.replaced {
                padding: 6px 11px 7px;
            }
        }

        input {
            bottom: 6px;
            padding-left: 11px;
        }

        img {
            right: 10px;
        }
    }

    &.required {
        border-color: ${ props => props.theme.colors.green.main };
    }

    &.error {
        border-color: ${ props => props.theme.colors.red.main };
        
        
    }

    &.disabled {
      border: solid 1px ${ props => props.theme.colors.gray.dark};
      background: ${ props => props.theme.colors.gray.light };
        input  {
            color: ${ props => props.theme.colors.gray.dark}
        }
    }

    .placeHolder {
        font-size: 12px;
        line-height: 16px;
        color: ${ props => props.theme.colors.gray.dark };
        position: absolute;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        padding: 12px 12px 8px;
        z-index: 2;
        transition: 0.1s all ease-out;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.replaced {
            padding: 7px 12px 8px;
            font-size: 8px;
            line-height: 8px;
            transition: none;
            z-index: 1;
        }

        &.replaced + input {
            z-index: 2;
        }
    }
    &.focused {

        img {
        }

        input  
        {
            z-index: 2;
        }
        .placeHolder {
            padding: 7px 12px 8px;

            font-size: 8px;
            line-height: 8px;
            .replaced {
                padding: 7px 12px 8px;
            }
        }
    }

    input  
    {
        width: 100%;
        position: absolute;
        z-index: 1;
        background-color: transparent;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 12px;
        line-height: 12px;
        bottom: 7px;
        left: 0;
    }

    img {
        position: absolute;
        top: 50%;
        margin-top: -7px;
        z-index: 3;
        right: 11px;
        cursor: pointer;
    }

    .errorText {
        color: ${ props => props.theme.colors.red.main };
        font-size: 10px;
        position: absolute;
        //bottom: -19px;
        bottom: -16px;

    }
`;
