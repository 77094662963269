import axios, {AxiosRequestConfig} from 'axios';
import {APIStorage} from '../Storage/api-storage';
import {StorageKeys, useLocalStorage} from 'src/Logic/hooks/useLocalStorage';

// eslint-disable-next-line react-hooks/rules-of-hooks
const { getFromTheStorage } = useLocalStorage(StorageKeys.AuthToken);

const storage = new APIStorage();

export const AUTH_URL = storage.apiLogin;

const ATTEMPTS_COUNT = 3;

const instance = axios.create({
  baseURL: storage.apiUrl,
  headers: {
    cache: 'no-cache',
    redirect: 'error',
    credentials: 'include',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // check internet
    if (!navigator.onLine) {
      function checkInternet(attemptsCount: number) {
        return new Promise((resolve) => {
          function repeat(count: number) {
            if (navigator.onLine || count + 1 >= ATTEMPTS_COUNT) return resolve(count);
            setTimeout(repeat.bind(null, count + 1), 5000);
          }

          repeat(attemptsCount);
        });
      }

      const requestAttemptCount = await checkInternet(0).then(result => {
        return result;
      });

      if (Number(requestAttemptCount) >= ATTEMPTS_COUNT) {
        return config;
      }
    }

    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${getFromTheStorage()}`;
    return config;
  },
  error => {
    return error;
  },
);

export default instance;
