import { QueryKeys } from "src/init/reactQuery"
import { getVenues } from "src/Logic/API/Venue/getVenues"
import { useSOFQuery } from "../useSOFQuery";

export const useVenueQuery = () => {

    const { data, isLoading, isError, } = useSOFQuery( QueryKeys.Venues, getVenues, { 
        enabled: true,
    });

    return {
        venues: data?.data.venues,
        isLoading,
        isError,
    }
}