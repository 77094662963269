// Elements
import { Col, Row, Form, FormInstance } from "antd";
import { Input } from "src/elements/input";
import { PrimaryBtn, SecondaryBtn } from "src/elements/buttons";

// Components
import { SOFWrapper } from "src/components/wrapper";
import { DateTimePicker } from "src/components/datePiker";

// Styles
import * as s from './styles';

// Moment
import moment from "moment-timezone";

type Props = {
    form: FormInstance<any>,
    onSearch: () => void,
}

export const SelectEventFilterPanel = ({ form, onSearch }: Props) => {

    const currentDate = moment();

    const handleClear = () => {
        form.resetFields();
    }

    const handleSearch = () => {
        onSearch();
    }

    return (
        <SOFWrapper className = "relative maxWidthPrimary">
            <s.Panel>
                <Form form = { form } className = 'filterPanelForm' >
                        <Row gutter={[ 20, 20 ]}>
                        <Col className="gutter-row">
                            <Form.Item className = 'largeFilter' name="venueEventId">
                                <Input placeholder = 'Event ID' type = "number"  />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item className = 'largeFilter' name="searchText">
                                <Input placeholder = 'Event Name'  />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item className = 'mediumFilter' name="date">
                                <DateTimePicker 
                                    dateClassName = {( date ) => { 
                                        if( date.isBefore(moment(), 'D') )
                                            return 'pastDate';
                                     }}
                                    placeholder = 'Start Date'
                                    markedDate = { currentDate }
                                />
                            </Form.Item>
                        </Col>
                        <Col  style={{paddingRight: 0}}>
                            <s.Div>
                                <PrimaryBtn 
                                    onClick = { handleSearch }  
                                    className = 'searchButton'
                                >Search</PrimaryBtn>
                                <SecondaryBtn onClick = {handleClear} className={'resetButton none-border'}>Reset</SecondaryBtn>
                            </s.Div>
                        </Col>
                        </Row>
                    </Form>
            </s.Panel>
        </SOFWrapper>
    );
}