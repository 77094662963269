import { useEffect, useState } from "react";
import { FileDataType, FileStatuses } from "src/pages/photoUpload/types";
import { UploadMethodTypes, useFileUploadMutation } from "./useFileUploadMutation";
import { v3 } from 'murmurhash';
import { useErrorAPIHandle } from "../useErrorApi";

type UploadedFilesType = {
    hash: number | string,
    status: FileStatuses,
    [ x:string ]: any,
}

type Props = {
    maxThreads: number;
    allowedTypes?: string[],
    uploadedFiles?: UploadedFilesType[],
    onFileUpload?: ( data:  FileDataType ) => void,
    onAbort?: () => void,
    // onComplete: () => void,
    APIConfig: {
        url: string,
        method?: UploadMethodTypes,
    },
};

export const useFileUploader = ({ maxThreads, allowedTypes, APIConfig, uploadedFiles, onFileUpload, onAbort }: Props) => {

    const [ files, setFiles ] = useState<FileDataType[]>([]);
    const [ threads , setThreads ] = useState( 0 );
    const [ aborted, setAborted ] = useState( false );
    const { errorHandle } = useErrorAPIHandle();
    
    const uploadMutation = useFileUploadMutation({ 
        onSuccess: ( data, vars ) => {
            fileStatusChange( vars.data.id, FileStatuses.Uploaded, vars.data.hash );
            onFileUpload && onFileUpload( vars.data )
        },
        onError: ( e, vars ) => {
            if( e.response?.status === 403 )
                fileStatusChange( vars.data.id, FileStatuses.Uploaded, vars.data.hash );
            else if( e.message === 'canceled' ) {
                fileStatusChange( vars.data.id, FileStatuses.Stopped, vars.data.hash );
            } else {
                fileStatusChange( vars.data.id, FileStatuses.Failed, vars.data.hash );
                errorHandle( e );
            }
        },
        onSettled: () => {
            setThreads(t => t - 1);
        },
    });

    useEffect(() => {
        setFiles(( prevFiles ) => {
            if( prevFiles.length > 0 && uploadedFiles && uploadedFiles.length > 0 ) {
                const proccessedFiles = prevFiles.map(( f ) => {
                    if( f.hash !== undefined ) {
                        const fileWithSameHash = uploadedFiles.find( uf => Number( uf.hash ) === f.hash );
                        if( fileWithSameHash ) 
                            return { ...f, status: fileWithSameHash.status };
                        return f;
                    } 
                    return f;
                });
                return proccessedFiles;
            }
            return prevFiles;
        });
    }, [ uploadedFiles ]);

    // ------------- Files Upload Controler  -------------
    useEffect(() => {
        if( !aborted ) {
            const idleFileIndex = files.findIndex( f => f.status === FileStatuses.Waiting );
            if ( idleFileIndex !== -1 && threads < maxThreads ) {
                uploadImage( files[ idleFileIndex ] );
                setThreads( t => t + 1 );
            }
        } else { 
            if( files.length > 0 ){
                let needToUpdate = false;
                const proccessedFiles = files.map(( f ) => {
                    if( f.status === FileStatuses.Waiting ) {
                        needToUpdate = true;
                        return { ...f, status: FileStatuses.Stopped };
                    }
                    return f;
                });
                needToUpdate && setFiles( proccessedFiles );
            }
            setAborted( false );
        }
    }, [ files, threads, maxThreads, aborted ]);

    // ------------- Inner functions -------------
    const  fileStatusChange = ( id: number, status: FileStatuses, hash?: number ) => {
        setFiles( prevState => prevState.map(( f ) => { 
            if( id === f.id )
                return { ...f, status, hash }
            return f;
        }))
    }

    const uploadImage = ( file: FileDataType ) => {
        
        fileStatusChange( file.id, FileStatuses.Uploading );
        hasher( file.file, ( hash ) => {
            if( !uploadedFiles || ( uploadedFiles && !uploadedFiles.some(( f ) => Number( f.hash ) === hash ))) 
                uploadMutation.mutate({ 
                    data: { ...file, hash }, 
                    url: APIConfig.url, 
                    method: APIConfig.method 
                });
            else {
                fileStatusChange( file.id, FileStatuses.Uploaded, hash );
                setThreads( t => t - 1 );
            }
        });
    };

    // ------------- Outer Functions -------------
    const addFiles = (Files: FileList) => {
        const filesToAdd: FileDataType[] = [];

        for (let i = 0; i < Files.length; i++) {
            const file = Files.item(i);
            if (file) {
                if ( allowedTypes && ( allowedTypes as string[] ).includes(file.type)) {
                    filesToAdd.push({
                        file: file,
                        status: FileStatuses.Waiting,
                        id: files.length + filesToAdd.length,
                    })
                } else if( !allowedTypes ) filesToAdd.push({ 
                    file: file,
                    status: FileStatuses.Waiting,
                    id: files.length + filesToAdd.length,
                })
            }
        }
        if( filesToAdd.length > 0 ) 
            setFiles([ ...files, ...filesToAdd ]);
    }

    const stopUploading = () => { 
        if( !aborted ) {
            setAborted( true );
            uploadMutation.reset();
            onAbort && onAbort();
        }
    };

    const reuploadFailedFiles = () => {
        setFiles(( prevFiles ) => {  return prevFiles.map(( f ) => {
            if( f.status === FileStatuses.Failed || f.status === FileStatuses.Stopped )
                return { ...f, status: FileStatuses.Waiting };
            return f; 
        })});
    }

    const resetUploader = () => {
        threads > 0 && stopUploading();
        setFiles([]);
    }

    return {
        addFiles,
        stopUploading,
        reuploadFailedFiles,
        resetUploader,
        files,
        aborted,
    }
};


const hasher = ( file: File, resultHandler: ( hash: number ) => void ) => {
    const reader = new FileReader();
    reader.onload = ( e ) => {
        const loadedData = e.target?.result;
        if( loadedData ) {
            const hash = v3( loadedData as string );
            resultHandler( hash );
        }
    }
    reader.readAsBinaryString( file );
}