import { AxiosError } from "axios";
import { useCallback, useState, } from "react";
import { UseMutationOptions } from "react-query";
import API from 'src/Logic/API/API';
import { FileDataType } from "src/pages/photoUpload/types";
import { useSOFMutation } from "../useSOFMutation";

export type UploadMethodTypes = 'PUT' | 'POST'; 

export interface IAPIOptions {
    url: string
    data: FileDataType,
    method?: UploadMethodTypes, 
}

export const useFileUploadMutation = (
    options?: Omit<
        UseMutationOptions<void, AxiosError, IAPIOptions>,
        'mutationFn'
    > 
) => {
    const [ abortControler, setAbortControler ] = useState( new AbortController() )

    const mutation = useSOFMutation<void, AxiosError, IAPIOptions>((args) => {
        const queryParams = { 
            filename: args.data.file.name.split('.')[0], 
            fileHash: args.data.hash,
        };

        if( args.method === 'POST')
            return API.post(args.url, args.data.file, {
                params: {
                    ...queryParams,
                },
                signal: abortControler.signal,
                headers:{ 
                    'Content-Type': args.data.file.type,
                }
            });
        
        return API.put(args.url, args.data.file, {
            params: {
                ...queryParams,
            },
            signal: abortControler.signal,
            headers:{ 
                'Content-Type': args.data.file.type,
            }
        });

    }, options );

    const reset = useCallback(() => {
        abortControler?.abort();
        mutation.reset();
        setAbortControler( new AbortController());
        // setProgress(0);
    }, [ mutation.reset, abortControler ]);

    return { ...mutation, abortControler, reset }
}