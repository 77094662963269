// Core
import React from 'react';
import ReactDOM from 'react-dom';

// Redux
import { Provider as ReduxProvider } from 'react-redux';
import { store as reduxStore } from './init/redux';

// React Router
import { BrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from 'react-query';
// Components
import App from 'src/app';
import reportWebVitals from 'src/reportWebVitals';

// Styles
import { ThemeProvider } from 'styled-components';

// Theme
import { SOFTheme } from 'src/assets/theme';
import { queryClient } from 'src/init/reactQuery';

import { ReactQueryDevtools } from 'react-query/devtools';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={SOFTheme}>
      <ReduxProvider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ReactQueryDevtools
            panelProps={{
              style: {
                color: 'red',
              },
            }}
            initialIsOpen={false}
          />
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
