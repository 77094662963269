import styled from "styled-components"

const Wrapper = styled.div`
    background: #FFFFFF;
    border-radius: 6px;
    padding: ${ props => props.theme.paddings.main };
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
`;

type Props = {
    className?: string,
    style?: React.CSSProperties | undefined,
    children?: any, 
}

export const SOFWrapper = ({ className, children, style }: Props ) => {
    
    return (
        <Wrapper style = { style } className = { `SOFWraper${ className ? ` ${className}` : ''} `} >
            { children }
        </Wrapper>
    )   
}