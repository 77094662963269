import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(36, 42, 56, 0.7);
    overflow: auto;
`;

export type ModalProps = {
    screenOverflow?: boolean;
}

export const ModalPlacer = styled.div<ModalProps>`
    position: absolute;
    top: ${ props => props.screenOverflow ? 0 : 'unset'};
    padding: ${ props => props.theme.paddings.main } 0;
`;

export const Bg = styled.div`
    position: sticky;
    top:0;
    width: 100%;
    height: 100%;
`;

export const Modal = styled.div`
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${props => props.theme.paddings.extraLarge } ${ props => props.theme.paddings.main };
    background-color: #fff;
    z-index: 1000;
    border-radius: 8px;
    .selfiePlacer {
        max-width: 208px;
        min-width: 135px;
        padding-top: 130%;
        position: relative;
        overflow: auto;
        width: auto;
        flex: 2;
        background-position: center;
        background-size: cover;
    }
`;

export const Header = styled.div`
    margin-bottom: ${ props => props.theme.margins.extraLarge};
    font-size: 20px;
    line-height: unset;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
`;