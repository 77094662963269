import styled from "styled-components";

export const ProgressPanel = styled.div`

    margin-top: 25px;
    width: 500px;

    .progressToolbar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .progressBar {
        width: 100%;
        height: 6px;
        background-color: ${ p => p.theme.colors.gray.light };
        border-radius: 10px;
        
        .progress {
            border-radius: 10px;
            height: 100%;
            background-color: ${ p => p.theme.colors.blue.main };
            transition: all 0.3s linear;

        }
    }
`;

export const FilesStatusList = styled.div`
    width: 100%;
    background-color: ${ p => p.theme.colors.gray.main };
    margin-top: 14px; 
    padding: 0;
    margin-bottom: ${ p => p.theme.margins.main };
    max-height: 326px;
    overflow-Y: scroll;
    overflow-X: hidden;

    &::-webkit-scrollbar {
        width: 4px; 
        background-color: ${ p => p.theme.colors.gray.main };
    }
    
    
    &::-webkit-scrollbar-thumb {
        background-color: #636971;
        border-radius: 10px;
    }

    .fileLi {
        display: flex;
        background-color: ${ p => p.theme.colors.white.main };
        margin: 1px;
        margin-right: 2px;
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 4px;

        
        b {
            margin-left: ${ p => p.theme.margins.small };
        }
        
        .fileInfoPanel {
            display: flex;
            margin-left: 20px;
            justify-content: space-between;
            width: 100%;
            padding-right: ${ p => p.theme.paddings.main };

            .failed {
                color: ${ p => p.theme.colors.red.main };
            }
        }
    }
`;
