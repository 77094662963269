import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

const Navigation = styled.div`
  position: fixed;
  right: 0;
  top: 300px;
  display: flex;
  flex-direction: column;
  z-index: 1002;
  text-shadow: 0 0 5px white;

  a {
    color: #000 !important;
  }
`;

export const NavigationBox = () => {
    return (
        <Navigation>
            {/* <Link to="/user/setting">Settings</Link>
            <Link to="/Media/SelfieList">Users</Link>
            <Link to="/selfie/matching">Matching</Link>
            <Link to="/Venue/ViewVenues">Venues</Link>
            <Link to="/VenueContact/ViewVenueContacts">Venue Contact</Link>
            <Link to="/VenueEvent/ViewEvents">VenueEvents</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/extractAppearancesAndFanTags">ExtractAppearancesAndFantags</Link>
            <Link to="/log">Log</Link> */}

            <Link to="/phone-number">SignIn</Link>
            <Link to="/verification-code">VerificationPhoneNumber</Link>
            <Link to="/select-venue">Select Venue</Link>
            <Link to="/select-venue-event">Select Venue Event</Link>
            <Link to="/photo-upload">Upload Photo</Link>
            {/* <Link to="/personal-information">RegPersonalInformation</Link> */}
            {/* <Link to="/terms-use">TermsOfUse</Link>
            <Link to="/send-verification-link">SendVerificationLink</Link>
            <Link to="/send-verification-link/check-email">CheckEmail</Link>
            <Link to="/email-verification-status">EmailVerificationStatus</Link> */}
            {/* <Link to="/activities-list">ActivityList</Link>
            <Link to="/activity-type">ActivityType</Link>
            <Link to="/activity">SelectActivity</Link>
            <Link to="/training-materials">TrainingMaterials</Link> */}
        </Navigation>
    );
};
