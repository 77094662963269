import { useEffect, useState } from 'react';
import { PrimaryBtn } from 'src/elements/buttons';
import { Div, Wrapper } from 'src/pages/SignIn/EnterPhoneNumber/styles';
import { PhoneSelectInput } from 'src/components/phoneSelectInput/phoneSelectInput';
import { countries, CountryType } from 'src/utils/countries/countries';
import { usePhoneNumberMutation } from 'src/Logic/hooks/SignIn/usePhoneNumberMutation';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRequestsLimitError } from 'src/Logic/actions/errorType/actions';
import { SignInPhoneDataType, PhoneStateType,  } from './types';
import { AppState } from 'src/init/redux';
import { LogoComponent } from 'src/components/logo/logoComponent';
import { StorageKeys, useLocalStorage } from 'src/Logic/hooks/useLocalStorage';
import { useErrorAPIHandle } from 'src/Logic/hooks/useErrorApi';
import {
    ResponsePhoneNumberType
} from "src/Logic/API/Verification/SendPhoneNumberPost/types";
import { VARIFICATION_CODE } from 'src/components/header/constants';

const US_CODE = 'US';
const NUMBER_LENGTH_WITHOUT_US_CODE = 10;
const US_CODE_LENGTH = 2;
const SLICE_WITHOUT_US_CODE = 2;
const SLICE_WITH_OTHER_COUNTRY_CODE = 1;
const MIN_NUMBER_LENGTH = 10;
const MAX_NUMBER_LENGTH = 15;
const DEFAULT_COUNTRY = countries[countries.length - 1];

export const EnterPhoneNumber = () => {
    const dispatch = useDispatch();
    const requestLimitError = useSelector<AppState, boolean>(
        state => state.errorTypeReducer.requestsLimitError,
    );

    const { getFromTheStorage: getPhoneData} = useLocalStorage( StorageKeys.PhoneData );
    const { saveToTheStorage: savePhoneData } = useLocalStorage(StorageKeys.PhoneData);
    const { resetStorage: resetToken } = useLocalStorage(StorageKeys.AuthToken);

    const [selectedCountry, setSelectedCountry] = useState<CountryType>(DEFAULT_COUNTRY);
    const [inputValue, setInputValue] = useState(DEFAULT_COUNTRY.phone);
    const [phoneCode, setPhoneCode] = useState(DEFAULT_COUNTRY.phone);

    const [isAcceptableNumberLength, setIsAcceptableNumberLength] = useState<boolean>(false);

    const [error, setError] = useState<string>('');
    const disabled = !!error || !isAcceptableNumberLength || requestLimitError;

    const navigate = useNavigate();
    const location = useLocation();
    const { state: phoneState } = location as PhoneStateType;
    const { errorHandle } = useErrorAPIHandle();

    const { isLoading: sendNumberLoading, mutateAsync } = usePhoneNumberMutation({
        onSuccess: ( data, vars ) => {
            navigate( VARIFICATION_CODE, {
                state: {
                    phoneNumber: vars.phoneNumber,
                    countryCode: vars.countryCode,
                    requestId: data.data.requestId,
                },
            });
        },
        onError: ( error: AxiosError<ResponsePhoneNumberType> ) => {
             const status = error.response ? error.response.status : null;

            if (status === 429) {
                dispatch(setRequestsLimitError({ requestsLimitError: true }));
                return;
            }
            if (status === 400) {
                setError('Filed Invalid phone number.');
                return;
            }
            if (status === 500) {
                setError('SMS Provider Error. Please check phone number.');
                return;
            }

            errorHandle(error);
        },
    });

    useEffect(() => {
        resetToken();
        const phoneData = phoneState ? phoneState : getPhoneData() as SignInPhoneDataType;
        if( phoneData ) {
            const previousCountry = countries.find(value => value.code === phoneData.countryCode);
            if (previousCountry) {
                setSelectedCountry(previousCountry);
                setPhoneCode(previousCountry.phone);
            } else {
                console.log('Cannot find previous country and phone');
            }
            if (phoneData.phoneNumber) {
                setInputValue(phoneData.phoneNumber);
                setIsAcceptableNumberLength(true);
            }
        }
    }, [ phoneState ]);

    useEffect(() => {
        if( phoneState?.errorMessage )
            setError( phoneState.errorMessage );
    }, [ phoneState?.errorMessage ]);

    useEffect(() => {
        if (requestLimitError) {
            setError('Phone number is temporarily banned for verification.');
        }
    }, [requestLimitError]);

    const selectCountry = (country: CountryType) => {
        setSelectedCountry(country);
    };

    const changePhoneCode = (phoneCode: string) => {
        setPhoneCode(phoneCode);
    };

    const changeInputValue = (inputValue: string) => {
        if (requestLimitError) {
            dispatch(setRequestsLimitError({ requestsLimitError: false }));
        }

        if (error) {
            setError('');
        }
        validateNumber(inputValue);
        setInputValue(inputValue);
    };

    const validateNumber = (phoneNumber: string) => {
        if (phoneNumber) {
            
            selectedCountry.code === US_CODE ? americanFormat(phoneNumber) : otherFormat(phoneNumber);
        } else {
            setIsAcceptableNumberLength(false);
        }
    };

    const americanFormat = (phoneNumber: string) => {
        const numberLength = phoneNumber.slice(SLICE_WITHOUT_US_CODE);
        if (numberLength?.length === NUMBER_LENGTH_WITHOUT_US_CODE) {
            return setIsAcceptableNumberLength(true);
        }

        return setIsAcceptableNumberLength(false);
    };

    const otherFormat = (phoneNumber: string) => {
        const numberLength = phoneNumber.slice(SLICE_WITH_OTHER_COUNTRY_CODE);
        if (numberLength.length >= MIN_NUMBER_LENGTH && numberLength.length <= MAX_NUMBER_LENGTH) {
            return setIsAcceptableNumberLength(true);
        }

        return setIsAcceptableNumberLength(false);
    };

    const sendPhone = async () => {
        const phoneNumber = inputValue;
        const countryCode = selectedCountry.code;
        await mutateAsync({ phoneNumber, countryCode });
        await savePhoneData({ countryCode, phoneNumber });
    };

    return (
        <Wrapper>
            <div className={`authContentWrapper`}>
                <LogoComponent className = 'authLogo' />
                <div className={`inputBlockWrapper`}>
                    <PhoneSelectInput
                        className={error ? 'phoneInputError' : ''}
                        countries={countries}
                        selectedCountry={selectedCountry}
                        inputValue={inputValue}
                        phoneCode={phoneCode}
                        changeInputValue={changeInputValue}
                        selectCountry={selectCountry}
                        changePhoneCode={changePhoneCode}
                        maxLength = {  selectedCountry.code === US_CODE ? 
                            NUMBER_LENGTH_WITHOUT_US_CODE + US_CODE_LENGTH : MAX_NUMBER_LENGTH }
                    />
                    <span className={`error standart-error-text ${error ? 'errorTextTrue' : null}`}>
                        {error}
                    </span>
                </div>
                <p className={`infoText standart-text`}>
                By continuing, you confirm that you’re the owner or primary user of this mobile phone
                number. You agree to receive automated texts to confirm your phone number. Message and
                data rates may apply.
                </p>
                <Div>
                    <PrimaryBtn 
                        showSpinner = { sendNumberLoading }  
                        className="continueBtn" 
                        disabled={disabled} 
                        onClick={sendPhone}
                        qa-data-id = 'continue-button'
                    >
                        Continue
                    </PrimaryBtn>
                </Div>
            </div>
        </Wrapper>
    );
};
