export enum StorageKeys {
    AuthToken = 'AuthToken',
    // RegistrationFormValues = 'RegistrationFormValues',
    PhoneData = 'PhoneData',
}

export const useLocalStorage = (storageKey: StorageKeys ) => {

    // const storageKey = 'VenuesFilterValues';
    const saveToTheStorage = (filtersValues:any) => {
        const stringifiedValue = JSON.stringify(filtersValues);
        localStorage.setItem(storageKey, stringifiedValue);

    }

    const getFromTheStorage = () => {
        const value = localStorage.getItem(storageKey);
        if(value)
        {
            const parsed = JSON.parse(value);
            if(parsed)
                return parsed;
        }
    }

    const resetStorage = () => {
        localStorage.removeItem(storageKey);
    }

    return {
        saveToTheStorage,
        getFromTheStorage,
        resetStorage,
    }
}
