import moment from 'moment-timezone';

export function ToInt(value: string, defaultValue: number = 0): number {
  const data = parseInt(value);
  if (isNaN(data)) return defaultValue;

  return data;
}

const dateRegex = /Date\((.*?)\)/;

export function extractTimeStampFromDate(dateValue: string): number {
  const regexResult = dateRegex.exec(dateValue);
  if (!regexResult) return 0;
  if (!regexResult[1]) return 0;

  const numberResult = parseInt(regexResult[1]);
  if (isNaN(numberResult)) return 0;

  return numberResult;
}

export function ToDate(dateValue: string): Date {
  const timestamp = extractTimeStampFromDate(dateValue);
  if (timestamp === 0) return new Date();
  const local = new Date(timestamp);
  return new Date(local.getTime() + local.getTimezoneOffset() * 60000);
}

export function ToShortDate(dateValue: string) {
  return ToDate(dateValue).toLocaleDateString('en-US');
}

export function ToTimeDate(dateValue: string) {
  return moment(ToDate(dateValue)).format('hh:mmA MM/DD/YYYY');
}

export function ToDMYDate(dateValue: string) {
  return moment(dateValue).format('DD/MM/YYYY');
}

//TODO needed in refactor converter
export function MainDateFormat(dateValue: string | undefined) {
    if( dateValue ) {
    const mainData = moment(dateValue).format(`MM/DD/YYYY`);
    const hoursMinutes = moment(dateValue).format(`hh:mm A`);
    return `${mainData}, ${hoursMinutes} ET`;
  }
}

export function ToTime(dateValue: string) {
  return ToDate(dateValue).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function SecondsInMilliseconds(seconds: number) {
  return seconds * 1000;
}

export const splitAndUpperCase = ( str: string | undefined ) => {
    if( str && str.length > 0 ) {
        const arrayOfChars = str.split('');

        return arrayOfChars.reduce(( acc, current, index ) => { 
            if( index === 0 )
                acc.push( current.toUpperCase());
            else {
                if( current === current.toUpperCase()) 
                    acc.push(' ');
                acc.push( current );
            }
            return acc;
        }, [] as string[]).join('');
    }
    return null;
}

export const ToETDateTime = ( value: string ) => {
  return moment( value ).utc().subtract(5, 'hours').toISOString();
}

export const ToStandartDatePresentation = ( value: string, ET?: boolean ) => {
    const momentValue = moment.parseZone( value );
    if( ET )
        momentValue.tz('America/New_York');

    return `${ momentValue.format('MM/DD/yyyy') }${ ET ? ' ET' : '' }`;
}

export const ToStandartDateTimePresentation = ( value: string, ET?: boolean ) => {
    const momentValue = moment.parseZone( value );
    if( ET )
        momentValue.tz('America/New_York');
    const date = momentValue.format('MM/DD/yyyy');
    const time = momentValue.format('hh:mm A');

    return `${ date } ${ time }${ ET ? ' ET' : '' }`;
}