export class EnvironmentStorage {
    private static envKeysInternal: string[] | null = null;
    private static readonly reactPrefix = "REACT_APP_";

    protected getString(name: string, defaultValue:string = ""): string {
        return this.getReactVariable(name, defaultValue);
    }

    protected getInteger(name: string, defaultValue:number = 0): number {
        const value = this.getReactVariable(name, defaultValue.toString());
        const parsedValue = parseInt(value);
        return isNaN(parsedValue) ? defaultValue : parsedValue;
    }

    protected getFloat(name: string, defaultValue:number = 0): number {
        const value = this.getReactVariable(name, defaultValue.toString());
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? defaultValue : parsedValue;
    }

    private getReactVariable(name: string, defaultValue:string = ""): string {
        const realName = `${EnvironmentStorage.reactPrefix}${name.toUpperCase()}`;
        if (!this.envKeys.some((value) => value === realName)) return defaultValue;
        const value = process.env[realName];
        return value ?? defaultValue;
    }

    private enumerateEnvKeys(): string[] {
        const names: string[] = [];
        for (const key in process.env) {
            names.push(key);
        }

        return names;
    }

    private get envKeys(): string[] {
        if (EnvironmentStorage.envKeysInternal === null) {
            EnvironmentStorage.envKeysInternal = this.enumerateEnvKeys();
        }

        return EnvironmentStorage.envKeysInternal;
    }
}