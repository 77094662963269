// Redux
import { useDispatch } from "react-redux";

// Hooks
import { useSelector } from "./useReduxSelector";

// Actions
import { setTechnicalErrorAction } from "../actions/togglers";

// types
import { TechnicalErrorStateType } from "../reducers/togglersReducer";

export const useTogglers = () => {
    const dispatch = useDispatch();
    return {
        technicalError: useSelector((state) => {return state.togglersReducer.technicalError}), 
        setTechnicalError: (payload: TechnicalErrorStateType ) => void dispatch(setTechnicalErrorAction( payload )),
    };
};
