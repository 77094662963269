// Core
import React from 'react';

// CSS
import 'antd/dist/antd.css';
import 'src/assets/styles/app.scss';

// Router
import { Navigate, Route, Routes } from 'react-router-dom';

// Pages
import { EnterPhoneNumber } from 'src/pages/SignIn/EnterPhoneNumber';
import { ErrorPage } from 'src/pages/ErrorPage';
import { VerificationCodeWithSignIn } from 'src/pages/SignIn/VerificationCodeWithSignIn';
import { SelectVenuePage } from './pages/SelectVenue';
import { SelectVenueEventPage } from './pages/SelectVenueEvent';
import { PhotoUploadPage } from './pages/photoUpload';

// Components
import { ErrorModal } from './components/modals/errorModal';
import { NavigationBox } from './components/Navigation';
import { Header } from 'src/components/header/header';

// Paths
import { 
    ACCOUNT_DISABLED, 
    NOT_FOUND, 
    PHONE_NUMBER, 
    SELECT_VENUE, 
    SELECT_VENUE_EVENT, 
    UPLOAD_PHOTO, 
    VARIFICATION_CODE,
} from './components/header/constants';
import { InitialPage } from './pages/Initial';

function App() {

  return (
    <div className={`mainContainer`}>
        <Header />
        <Routes>
            <Route path = "/" element={ <InitialPage /> } />
            <Route path = "*" element={<Navigate to = { NOT_FOUND } replace />} />
            <Route path = { PHONE_NUMBER } element={<EnterPhoneNumber />} />
            <Route path = { VARIFICATION_CODE } element={<VerificationCodeWithSignIn />} />
            <Route path = { SELECT_VENUE } element={<SelectVenuePage />} />
            <Route path = { SELECT_VENUE_EVENT } element={<SelectVenueEventPage />} />
            <Route path = { UPLOAD_PHOTO } element={<PhotoUploadPage />} />
            <Route path = { ACCOUNT_DISABLED } element={<ErrorPage />} />
            <Route path = { NOT_FOUND } element={<ErrorPage />} />
        </Routes>
        {process.env.NODE_ENV === 'development' && <NavigationBox />}
        <ErrorModal />
    </div>
  );
}

export default App;
