import { Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SELECT_VENUE_EVENT } from "src/components/header/constants";
import { LoadingSpinner } from "src/components/loadingSpinner/loadingSpinner";
import { PrimaryBtn } from "src/elements/buttons";
import { useUploadData } from "src/Logic/hooks/useUploadData";
import { useVenueQuery } from "src/Logic/hooks/Venue/useVenueQuery";
import * as s from "./styles";
import { TableColumnSettings } from "./columnSettings";


type PageModes = 'Select' | 'Error' | 'Loading';

export const SelectVenuePage = () => {

    const [ columnSettings, setColumnSettings ] = useState([ ...TableColumnSettings ]);
    const [ pageMode, setPageMode ] = useState<PageModes>('Loading');

    const navigate = useNavigate();
    const { setVenueId } = useUploadData();
    const { venues, isLoading, isError } = useVenueQuery();

    useEffect(() => {
        if( isLoading )
            setPageMode('Loading');
        else if( venues ) {
            if( venues.length > 0)
                setPageMode('Select');
            else
                setPageMode('Error')
        } 
        if( isError ) 
            setPageMode('Error')

    }, [ venues, isLoading, isError ]);
    

    useEffect(() => {
        setColumnSettings(( prevSettings ) => { 
            const newSettings = [ ...prevSettings ];

            newSettings[ newSettings.length - 1 ].render = ( id: number ) => {
                return ( 
                    <div>
                        <PrimaryBtn
                            className = "selectBtn"
                            onClick = {() => { 
                                setVenueId( id );
                                navigate( SELECT_VENUE_EVENT );
                            }}
                        >Select</PrimaryBtn>
                    </div>
                )
            }

            return newSettings;
        })

    }, [ venues ]);

    const renderContent = () => {

        switch( pageMode ) {
            case 'Select': {
                return (
                    <div className = 'tableContatiner' >
                        <Table 
                            dataSource = { venues?.map(( v ) => {
                                return {
                                    id: v.id,
                                    name: v.name,
                                    city: v.city,
                                    state: v.state,
                                    country: v.countryCode,
                                    action: v.id,
                                }
                            }) }
                            rowKey = { r => r.id }
                            columns = { columnSettings }
                            className = 'dashboardTable  selectVenueTable'
                            pagination = { false }
                        />
                    </div>
                )
            }   
            case 'Error': {
                return ( 
                    <span className = "message">Sorry, you do not have access to any venues.
                        Please contact the administrator.</span>
                );
            }
            case 'Loading': {
                return (
                    <div className = "d-flex flex-column align-items-center">
                        <LoadingSpinner loadingText = "Loading venues..." />
                    </div>
                )
            }
        }
    }

    return (
        <s.Wrapper>
            <span className = "title" >Select Venue</span>
            { renderContent() }
        </s.Wrapper>
    );
}