// Core
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

// Reducers
import { togglersReducer } from 'src/Logic/reducers/togglersReducer';
import {errorTypeReducer} from 'src/Logic/reducers/errorTypeReducer';
import { uploadDataReducer } from 'src/Logic/reducers/uploadDataReducer';


// Middlewares
import { middlewares } from './middlewares';

export const rootReducer = combineReducers({
    togglersReducer,
    errorTypeReducer,
    uploadDataReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
