import { PrimaryBtn } from "src/elements/buttons";

export const TableColumnSettings = [
    {
        title: () => {return <div style={{minWidth: 'max-content'}}>ID</div>},
        dataIndex: 'id',
        key: 'id',
        className: 'tableColumn noHeight firstColumn',
        render: (data: any ) => {
            return (
                data
                );
        }
    },
    {
        className: 'tableColumn noHeight',
    },
    {
        title: () => {return <div style={{minWidth: 'max-content'}}>Name</div>},
        dataIndex: 'name',
        key: 'name',
        className: 'tableColumn noHeight',
        render: (value: string ) => {
            return value;
        }
    },
    {
        className: 'tableColumn noHeight',
    },
    {
        title: () => {return <div style={{minWidth: 'max-content'}}>Start Date</div>},
        dataIndex: 'startDate',
        key: 'startDate',
        className: 'tableColumn noHeight',
        render: ( venue: string ) => {
            return venue;
        }
    },
    {
        className: 'tableColumn noHeight',
    },
    {
        title: () => {return <div style={{minWidth: 'max-content'}}>Start Time</div>},
        dataIndex: 'startTime',
        key: 'startTime',
        className: 'tableColumn noHeight',
        render: ( value: number ) => {
            return value;
        }
    },
    {
        className: 'tableColumn noHeight',
    },
    {
        title: () => {return <div style={{minWidth: 'max-content'}}>Action</div>},
        dataIndex: 'action',
        key: 'action',
        className: 'tableColumn noHeight lastColumn',
        render: ( id: number ) => {
            return ( 
                <div><PrimaryBtn className = "selectBtn">Select</PrimaryBtn></div>
            );
        }
    },
];

