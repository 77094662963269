import { QueryKeys } from "src/init/reactQuery"
import { getUploadedMasterPhotos } from "src/Logic/API/PhotoUpload/getUploadedFiles";
import { useSOFQuery } from "../useSOFQuery";
import { useUploadData } from "../useUploadData";

export const useUploadedMPhotosQuery = () => {
    const { venueEventId } = useUploadData()
    const { data, refetch, isRefetching, isLoading } = useSOFQuery( 
        QueryKeys.UploadedFiles, () => getUploadedMasterPhotos({ venueEventId: venueEventId! }), {
        enabled: !!venueEventId 
    });

    const getUploadedFiles = () => {
        refetch();
    }

    return { 
        uploadedFiles: data?.data.files,
        venueEventId,
        getUploadedFiles,
        isMasterPhotosLoading: isRefetching || isLoading,
    }
} 