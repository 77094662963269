import styled from "styled-components";

const Container = styled.div`
    
    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        width: 23px;
        height: 23px;
        
        &.big {
            width: 125px;
            height: 125px;

            & .path {
                stroke: ${ p => p.theme.colors.blue.main};
                stroke-width: 3px;
            }
        }

        & .path {
            stroke: ${ p => p.theme.colors.white.main };
            stroke-linecap: round;
            stroke-width: 4px;
            animation: dash 1.5s ease-in-out infinite;
        }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

`;

type Props = {
    className?: string | undefined,
    style?: React.CSSProperties,
}

export const CircleSpinner = ({ className, style }: Props) => {
    return (
        <Container>
            <svg 
                className = {`spinner${ className ? ` ${ className }` : ''}`} 
                style = { style } 
                viewBox="0 0 50 50"
            >
                <circle className = "path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
            </svg>
        </Container>
    )
}