import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.main};
  height: 67px;
  justify-content: space-between;
  align-items: center;

  .leftSideContainer {
    width: 200px;
    .goBackHeaderButton {
      cursor: pointer;
      height: 14px;
    }
  }

  .photoUploadLogo {
    margin-left: ${props => props.theme.margins.main};
    height: 25px;
    width: 25px;
    
    :disabled {
      cursor: auto;
    }
  }

  .title {
    color: #fff;
    text-transform: capitalize;
    font-size: 26px;
    font-weight: bold; 
  }

`;

export const ProfileBox = styled.div`
    color: #fff;
    display: flex;
    margin-right: ${ p => p.theme.margins.main };
    align-items: center;

    .userInfo {
        display: flex;
        flex-direction: column;
        
        b {
            line-height: 14px;
        }

        span: {
            font-size: 10px;
            line-height: 11px;
        }
    }
    
    .userInfoDivider {
        width: 1px;
        height: 23px;
        background-color: #fff;
        margin-right: ${ p => p.theme.margins.main };
        margin-left: ${ p => p.theme.margins.main };
    }

    .sOutButton {
        color: ${ p => p.theme.colors.green.main };
    }
`;