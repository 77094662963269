import * as types from './types';

export const setSelectedEventIDAction: types.SetSelectedEventIdContract = ( payload ) => ({
    type: types.SET_SELECTED_EVENT_ID,
    payload,

});
export const setSelectedVenueIDAction: types.SetSelectedVenueIdContract = ( payload ) => ({
    type: types.SET_SELECTED_VENUE_ID,
    payload,
});

