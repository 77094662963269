import API from 'src/Logic/API/API';
import { ErrorResponse } from 'src/Logic/models/Response/ErrorResponse';
import { MasterPhotoType } from 'src/Logic/models/Response/masterPhotos';

type Props = {
    venueEventId: number,
}

export const getUploadedMasterPhotos = ( { venueEventId }: Props ) => {
  return API.get<UploadedMasterPhotosResponse>(`api/venueEvents/${ venueEventId }/upload`);
};

export type UploadedMasterPhotosResponse = ErrorResponse & {
    files: MasterPhotoType[]
}