import { QueryKeys } from "src/init/reactQuery"
import { getProfileData as getProfileDataRequest } from "src/Logic/API/Profile/getProfileData"
import { useSOFQuery } from "../useSOFQuery"

export const useProfileQuery = () => {

    const { data, refetch, isError } = useSOFQuery( QueryKeys.ProfileData, getProfileDataRequest, { enabled: false });

    const getProfileData = () => {
        refetch();
    };

    return {
        getProfileData,
        profileData: data?.data.account,
        isError,
    }
}