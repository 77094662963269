import {RequestsLimitError, SignInErrorType} from './types';

export const setSignInError = (payload: SignInErrorType) => {
  return {
    type: 'SET_SIGN_IN_ERROR',
    payload,
  } as const;
};

export const setRequestsLimitError = (payload: RequestsLimitError) => {
  return {
    type: 'SET_REQUESTS_LIMIT_ERROR',
    payload,
  } as const;
};
