import {
    sendPhoneNumberPost
} from 'src/Logic/API/Verification/SendPhoneNumberPost/sendPhoneNumberPost';
import {useMutation} from 'react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {
    PhoneNumberParamsType,
    ResponsePhoneNumberType
} from "src/Logic/API/Verification/SendPhoneNumberPost/types";
import { useErrorAPIHandle } from '../useErrorApi';

type Props = {
    onError?: ((
        error: AxiosError<ResponsePhoneNumberType, any>,
        variables: PhoneNumberParamsType, context: unknown
    ) => void) | undefined,
    onSuccess?:  ((
        data: AxiosResponse<ResponsePhoneNumberType, any>, 
        variables: PhoneNumberParamsType, context: unknown
    ) => void) | undefined,
}
export const usePhoneNumberMutation = ({ onError, onSuccess }: Props) => {

    const { errorHandle } = useErrorAPIHandle()

    const mutationResult = useMutation<
        AxiosResponse<ResponsePhoneNumberType>,
        AxiosError<ResponsePhoneNumberType>,
        PhoneNumberParamsType>(sendPhoneNumberPost, {
            onSuccess,
            onError: onError ? onError : ( error ) => {
                errorHandle( error );
            },
        })

    return mutationResult;
}


