import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { PHONE_NUMBER, SELECT_VENUE } from 'src/components/header/constants';
import { LoadingSpinner } from 'src/components/loadingSpinner/loadingSpinner';
import { LogoComponent } from 'src/components/logo/logoComponent';
import { useProfileQuery } from 'src/Logic/hooks/profile/useProfileQuery';
import { Wrapper } from './styles';

export const InitialPage = () => {

    const { profileData, getProfileData, isError, } = useProfileQuery()
    const navigate = useNavigate();
   
    useEffect( () => {
        if( profileData )
            navigate( SELECT_VENUE, { replace: true } );
        else 
            getProfileData();
    }, [ profileData, getProfileData ]);

    useEffect(() => {
        isError && navigate( PHONE_NUMBER, { replace: true } );
     }, [ isError ])

    return (
        <Wrapper>
            <div className={`authContentWrapper`}>
                <LogoComponent className = 'authLogo' />
                    <LoadingSpinner className = 'initialSpiner' style = {{ marginBottom: 0 }} loadingText = 'Loading...' />
            </div>
        </Wrapper>
    )
}
