import { CustomErrorComponent } from 'src/components/customErrorComponent/customErrorComponent';
import AccountDisabledIcon from 'src/assets/images/accound-disabled-icon.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PHONE_NUMBER } from 'src/components/header/constants';

export const AccountDisabledComponent = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate( PHONE_NUMBER );
  };

  return (
    <CustomErrorComponent image={AccountDisabledIcon}>
      Sorry, this account is disabled.
      <br /> Back to Sign In
      <span className={`link-text`} onClick={ redirect }>
        {' here.'}
      </span>
    </CustomErrorComponent>
  );
};
