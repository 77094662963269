import { Reducer } from "redux";
import { UploadDataActionTypes } from "../actions/uploadData/types";

type StateType = {
    venueID: number | null,
    eventID: number | null,
}

const initialState:StateType = {
    eventID: null,
    venueID: null,
};

export const uploadDataReducer: Reducer<StateType, UploadDataActionTypes> = ( prevState = initialState, action ) => {
        switch (action.type) {
        case 'SET_SELECTED_VENUE_ID': 
        {
            return {...prevState, venueID: action.payload };
        }
        case 'SET_SELECTED_EVENT_ID': 
        {
            return {...prevState, eventID: action.payload };
        }
        default: {
            return prevState
        }
    }
}
