import moment from "moment";
import { FormInstance } from "rc-field-form";
import { QueryKeys } from "src/init/reactQuery"
import { getVenueEvents } from "src/Logic/API/VenueEvents/getVenueEvents";
import { FormDataType } from "src/pages/SelectVenueEvent";
import { useSOFQuery } from "../useSOFQuery";
import { useUploadData } from "../useUploadData";

export const useVenueEventsQuery = ( form: FormInstance<FormDataType> ) => {

    const { venueId } = useUploadData();

    const { data, isLoading, isRefetching, refetch, isError } = useSOFQuery( QueryKeys.VenueEvents, () => getVenueEvents({ 
        venueId: venueId!,
        ...form.getFieldsValue(),
        date: form.getFieldValue('date') ? moment(form.getFieldValue('date')).utc().hour(0).toISOString() : '',
    }), { 
        enabled: !!venueId,
    });

    
    const searchEvents = () => {
       venueId && refetch();
    }

    return {
        venueEvents: data?.data.venueEvents,
        venueId,
        isError,
        isLoading: isLoading || isRefetching,
        searchEvents,
    }
}