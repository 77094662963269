import PotohUploadSvg from 'src/assets/images/photo-upload.svg';

type Props = {
    className?: string | undefined,
}

export const LogoComponent = ({ className }: Props) => {
    return <img  
            src = { PotohUploadSvg }
            className = { className }
            alt = "Logo"
            style={{ marginBottom: '54px' }}
         />;
};
