import { LinkButton } from "src/elements/buttons";
import { splitAndUpperCase } from "src/Logic/Helpers/Converters";
import { FileDataType, FileStatuses } from "../types";
import * as s from './styles';

type Props = {
    progressPercents: number,
    onUploadStop: () => void,
    files: FileDataType[],
}

export const UploadDashboard = ({ progressPercents, onUploadStop, files }: Props ) => {
    return (
        <div style = {{ width: '100%' }} className = 'd-flex flex-column align-items-center'>
            <s.ProgressPanel>
                <div className = 'progressToolbar'>
                    <span>{ progressPercents }% Completed.</span>
                    <LinkButton 
                        disabled = { progressPercents === 100 } 
                        onClick = { onUploadStop } 
                    >Stop Uploading</LinkButton>
                </div>
                <div className = "progressBar" > 
                    <div style = {{ width: `${ progressPercents }%` }} className = "progress" ></div>
                </div>
            </s.ProgressPanel>
            <s.FilesStatusList>
            { 
                files.map(( f, index ) =>  <div key = { f.id } className = 'fileLi' >
                                                <b>{ index + 1 }</b>
                                                <div className = 'fileInfoPanel' >
                                                    <span>{ f.file.name }</span>
                                                    <span 
                                                        className = { 
                                                            f.status === FileStatuses.Failed ||
                                                            f.status === FileStatuses.Stopped ? "failed" : undefined } 
                                                    >{ splitAndUpperCase( f.status )}</span>
                                                </div>
                                            </div>
            )}
            </s.FilesStatusList>
        </div>
    );
}