// Components
import { Modal } from 'antd';

// Elements
import { PrimaryBtn } from 'src/elements/buttons';

// Redux
import { useTogglers } from 'src/Logic/hooks/useTogglers';

// Styled
import styled from 'styled-components';

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: ${props => props.theme.colors.main};
  margin-bottom: ${props => props.theme.margins.main};
  text-align: center;
`;

const Container = styled.div`
  margin-top: 6px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rollBackButton {
    max-width: 150px;
  }
  
  .messageDescription {
    margin-bottom: ${props => props.theme.margins.extraLarge};
    line-height: 20px;
  }
`;

export const ErrorModal = () => {
  const { technicalError, setTechnicalError } = useTogglers();

  return (
    <Modal visible={technicalError.isError} closable={false} footer={null}>
      <Container>
        <Title data-qa-id="video-cration-error-modal">
          {technicalError.title
            ? technicalError.title
            : 'A technical error occurred, it is all your fault, why did you press that button!'}
        </Title>
        {technicalError.message && (
          <span className={'standart-text messageDescription '}>{technicalError.message}</span>
        )}
        <PrimaryBtn
          className="rollBackButton"
          onClick={() => {
            setTechnicalError({ isError: false });
          }}
        >
          OK
        </PrimaryBtn>
      </Container>
    </Modal>
  );
};
