import {useMutation, UseMutationOptions, MutationFunction, UseMutationResult} from 'react-query';
import { AxiosError } from 'axios';
import { useErrorAPIHandle } from './useErrorApi';
import { ErrorResponse } from '../models/Response/ErrorResponse';


export const useSOFMutation = <
    TData = unknown, 
    TError = AxiosError<ErrorResponse, any>, 
    TVariables = void, 
    TContext = unknown
>( 
    mutationFn: MutationFunction<TData, TVariables>, 
    options?: Omit<UseMutationOptions<TData,  AxiosError<ErrorResponse, any>, TVariables, TContext>, 'mutationFn'> 
): UseMutationResult<TData,  AxiosError<ErrorResponse, any>, TVariables, TContext> => {
    
    const { errorHandle } =  useErrorAPIHandle();

    let customOnError: ((error:  AxiosError<ErrorResponse, any>, variables: TVariables, context: TContext | undefined) => void | Promise<unknown>) | undefined;
    if( options )
        customOnError = options.onError;
    const mutationResult = useMutation<TData,  AxiosError<ErrorResponse, any>, TVariables, TContext>( mutationFn, {
        ...options,
        onError: ( error, vars, ctx ) => { 
            if( customOnError )
                customOnError( error, vars, ctx );
            else
                errorHandle( error );
        }
    })
    return mutationResult;
}