import AG from 'src/assets/images/flags/ag.svg';
import BS from 'src/assets/images/flags/bs.svg';
import BB from 'src/assets/images/flags/bb.svg';
import BZ from 'src/assets/images/flags/bz.svg';
import CA from 'src/assets/images/flags/ca.svg';
import CR from 'src/assets/images/flags/cr.svg';
import CU from 'src/assets/images/flags/cu.svg';
import DM from 'src/assets/images/flags/dm.svg';
import DO from 'src/assets/images/flags/do.svg';
import SV from 'src/assets/images/flags/sv.svg';
import GD from 'src/assets/images/flags/gd.svg';
import GT from 'src/assets/images/flags/gt.svg';
import HT from 'src/assets/images/flags/ht.svg';
import HN from 'src/assets/images/flags/hn.svg';
import JM from 'src/assets/images/flags/jm.svg';
import MX from 'src/assets/images/flags/mx.svg';
import NI from 'src/assets/images/flags/ni.svg';
import PA from 'src/assets/images/flags/pa.svg';
import KN from 'src/assets/images/flags/kn.svg';
import LC from 'src/assets/images/flags/lc.svg';
import VC from 'src/assets/images/flags/vc.svg';
import TT from 'src/assets/images/flags/tt.svg';
import US from 'src/assets/images/flags/us.svg';

export type CountryType = {
  name: string
  code: string
  id: number
  phone: string
  flagURL: string
}

export const countries: CountryType[] = [
  {name: "Antigua and Barbuda", code: "AG", id: 1, phone: '+1', flagURL: AG},
  {name: "Bahamas", code: "BS", id: 2, phone: '+1', flagURL: BS},
  {name: "Barbados", code: "BB", id: 3, phone: '+1', flagURL: BB},
  {name: "Belize", code: "BZ", id: 4, phone: '+501', flagURL: BZ},
  {name: "Canada", code: "CA", id: 5, phone: '+1', flagURL: CA},
  {name: "Costa Rica", code: "CR", id: 6, phone: '+506', flagURL: CR},
  {name: "Cuba", code: "CU", id: 7, phone: '+53', flagURL: CU},
  {name: "Dominica", code: "DM", id: 8, phone: '+1', flagURL: DM},
  {name: "Dominican Republic", code: "DO", id: 9, phone: '+1', flagURL: DO},
  {name: "El Salvador", code: "SV", id: 10, phone: '+503', flagURL: SV},
  {name: "Grenada", code: "GD", id: 11, phone: '+1', flagURL: GD},
  {name: "Guatemala", code: "GT", id: 12, phone: '+502', flagURL: GT},
  {name: "Haiti", code: "HT", id: 13, phone: '+509', flagURL: HT},
  {name: "Honduras", code: "HN", id: 14, phone: '+504', flagURL: HN},
  {name: "Jamaica", code: "JM", id: 15, phone: '+1', flagURL: JM},
  {name: "Mexico", code: "MX", id: 16, phone: '+52', flagURL: MX},
  {name: "Nicaragua", code: "NI", id: 17, phone: '+505', flagURL: NI},
  {name: "Panama", code: "PA", id: 18, phone: '+507', flagURL: PA},
  {name: "Saint Kitts and Nevis", code: "KN", id: 19, phone: '+1', flagURL: KN},
  {name: "Saint Lucia", code: "LC", id: 20, phone: '+1', flagURL: LC},
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    id: 21,
    phone: '+1',
    flagURL: VC
  },
  {name: "Trinidad and Tobago", code: "TT", id: 22, phone: '+1', flagURL: TT},
  {name: "United States", code: "US", id: 23, phone: '+1', flagURL: US},
];
