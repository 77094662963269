// Redux
import { useDispatch } from "react-redux";

// Hooks
import { useSelector } from "./useReduxSelector";

// Actions
import { setSelectedEventIDAction, setSelectedVenueIDAction } from "../actions/uploadData";

export const useUploadData = () => {
    const dispatch = useDispatch();
    return {
        venueEventId: useSelector((state) => {return state.uploadDataReducer.eventID}), 
        venueId: useSelector((state) => {return state.uploadDataReducer.venueID}), 
        setEventId: (payload: number ) => void dispatch(setSelectedEventIDAction( payload )),
        setVenueId: (payload: number ) => void dispatch(setSelectedVenueIDAction( payload )),
    };
};
