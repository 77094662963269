import {useMutation, UseMutationOptions} from 'react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {
  sendVerificationCodePost
} from 'src/Logic/API/Verification/SendVerificationCodePost/sendVerificationCodePost';
import {
  ResponseVerificationCodeType,
  VerificationCodeParamsType
} from "src/Logic/API/Verification/SendVerificationCodePost/types";

export const useVerificationCodeMutation = (config?: UseMutationOptions<AxiosResponse<ResponseVerificationCodeType>, AxiosError<ResponseVerificationCodeType>, VerificationCodeParamsType>) =>
    useMutation<AxiosResponse<ResponseVerificationCodeType>, AxiosError<ResponseVerificationCodeType>, VerificationCodeParamsType>(sendVerificationCodePost, config)
