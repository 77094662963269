import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PHONE_NUMBER, SELECT_VENUE } from "src/components/header/constants";
import { setRequestsLimitError } from "src/Logic/actions/errorType/actions";
import { ResponseVerificationCodeType } from "src/Logic/API/Verification/SendVerificationCodePost/types";
import { VerificationParamsFromURLType } from "src/pages/SignIn/VerificationCodeWithSignIn/types";
import { useErrorAPIHandle } from "../useErrorApi";
import { StorageKeys, useLocalStorage } from "../useLocalStorage";
import { useSignInMutation } from "./useSignInMutation";
import { useVerificationCodeMutation } from "./useVerificationCodeMutation";

export const useSignInAndCode = () => {

    const [ varificationError, setVarificationError ] = useState<undefined | string>('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { errorHandle } = useErrorAPIHandle();
    const { saveToTheStorage: saveToken } = useLocalStorage(StorageKeys.AuthToken);
    const { state: phoneState } = location as VerificationParamsFromURLType;

    // ------------- Sign In -------------

    const { mutate: signIn, isLoading: isSignInLoading } = useSignInMutation({
        onSuccess: async () => {
            navigate( SELECT_VENUE );
        },
        onError: ( error ) => {
            const status = error.response ? error.response.status : null;
            const phoneData = {
                countryCode: phoneState.countryCode,
                phoneNumber: phoneState.phoneNumber,
            } 
            if ( status === 404 ) {
                navigate( PHONE_NUMBER, { 
                    state: {
                        ...phoneData,
                        errorMessage: 'No account found with this phone number.',
                    }  
                })
            }

            errorHandle( error );
        } 
    });
    // ---------------------------------------------

    // ------------- Varification Code -------------
    const { isLoading: sendVerificationCodeLoading, isError: isVarificationError, mutateAsync: sendVerificationCode } =
        useVerificationCodeMutation({
            onSuccess: (
                data: AxiosResponse<ResponseVerificationCodeType>,
            )  => {
                saveToken(data.data.authToken);
                signIn();
            },
            onError:  (error: AxiosError<ResponseVerificationCodeType>) => {
                const errorCode = error.response?.data.errorCode;
                const status = error.response ? error.response.status : null;

                if (status === 429) {
                    dispatch(setRequestsLimitError({ requestsLimitError: true }));
                    navigate( PHONE_NUMBER );
                    return;
                }

                if (errorCode === 'verificationCodeExpired') {
                    setVarificationError('Code expired');
                    return;
                }

                if (errorCode === 'incorrectVerificationCode') {
                    setVarificationError('Wrong code');
                    return;
                }

                errorHandle(error);
            },
        });

    const resetVarificationError = () => {
        setVarificationError('');
    }

    return {
        sendVerificationCodeLoading,
        isVarificationError,
        varificationError,
        isSignInLoading,
        resetVarificationError,
        sendVerificationCode,
        signIn,
   }
}
