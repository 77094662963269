import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.white.main};
  width: 670px;
  min-height: 433px;
  border-radius: 6px;
  margin: ${props => props.theme.margins.extraLarge} auto 0;

  .authContentWrapper {
    width: 360px;
    
    .authLogo {
      margin-left: calc(50% - 50px);
      margin-top: ${ p => p.theme.margins.extraLarge };
    }
  }

  .inputBlockWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 101;
  }

  .error {
    position: absolute;
    top: 42px;
    opacity: 0;
    z-index: -1;
  }

  .errorBorderTrue {
    //border: 2px solid ${props => props.theme.colors.red.main};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.red.main};
  }

  .errorTextTrue {
    opacity: 1;
  }

  .infoText {
    margin-top: 66px;
  }
`;

export const Div = styled.div`
  max-height: 40px;
  display: flex;
  margin-top: 7px;

  .continueBtn {
    max-width: 100%;
  }
`;
