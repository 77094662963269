import API from 'src/Logic/API/API';
import { VenueType } from 'src/Logic/models/General/Vеnues';
import { ErrorResponse } from 'src/Logic/models/Response/ErrorResponse';

export const getVenues = () => {
  return API.get<VenuesResponseType>(`api/venues`);
};

export type VenuesResponseType = ErrorResponse & {
    venues: VenueType[],
}