import styled from "styled-components";

// Styles
export const PickerWrapper = styled.div`
    height: 40px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid ${props => props.theme.colors.gray.dark};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 11px;  
    box-sizing: border-box;
    font-size: ${props => props.theme.sizes.font.main};
    position: relative;

    .pickerQaInput {
        display: none;
    }

    :hover { 
        border: 1px solid ${props => props.theme.colors.blue.input};
    }

    &.focused {
        border: 1px solid ${props => props.theme.colors.blue.input};
        box-shadow: 0px 0px 6px rgba(21, 143, 255, 0.3);
    }

    .inputWrapper {
        height: 100%;
        width: 100%;

        &:hover {
            .pickerClearBtn {
                display: flex;
            }
        }

        .pickerClearBtn {
            position: absolute;
            right: 11px;
            top: 50%;
            margin: 0;
            margin-top: -7px;
            display: none;
        }

        .placeholder {
            font-size: ${props => props.theme.sizes.font.main};
            color: ${props => props.theme.colors.gray.dark};
            padding-top: 6px;
            &.picked {
                font-size: 8px;
                padding-top: 0;
            }
        }

        .inputDataDisplayer {
            color: ${props => props.theme.colors.main};
            font-size: ${props => props.theme.sizes.font.main};
            line-height: ${props => props.theme.sizes.font.main};
            letter-spacing: 0.01em;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            padding-left: 11px;
            padding-right: 11px;
            top:0;
            left:0;

            &.withPlaceholder {
                padding-top: 10px;
            }

            &.picked {
            }
        }
    }
`;

interface PopUpI {
    theme: any,
    leftPosition: boolean,
}

export const PickerPopup = styled.div<PopUpI>`
    width: 482px;
    height: 304px;
    background: #FFFFFF;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
    padding: 22px 0 22px 17px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    top: 45px;
    left: ${props => props.leftPosition ? 0 : '-382px'};
    opacity: 1;
    transition: all 0.3s ease-out;
    transform-origin: ${props => props.leftPosition ? 'top left' : 'top right'};

    &.pickerHidden {
        transform: scale(0);
        opacity: 0;
    }

    img:hover {
        cursor: pointer;
    }

    .pickerDivider {
        min-width: 2px;
        height: 260px;
        background-color: ${props => props.theme.colors.gray.main};
        margin-left:30px;
        margin-right: 18px;
    }

    &.thin {
        width: 340px;
        left: ${props => props.leftPosition ? 0 : '-240px'};
    }
`;

export const DatePickerWrapper = styled.div`
    max-width: 306px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    span {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        letter-spacing: 0.12px;
    }
`;

export const TimePickerWrapper = styled.div`
    max-width: 130px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;

    img {
        margin-right: 50%;
    }
`;

export const TimeList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .scrollableList {
        overflow-y: scroll;
        height: 216px;
        margin-right: 12px;
        box-sizing: border-box;
        position: relative;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .timePickerScrollBar {
        overflow-y: scroll;
        height: 100%;
        width:5px;
        margin-right: 4px;
        position: relative;

        &::-webkit-scrollbar {
            width: 4px;
            background: #f1f1f1;
            border-radius: 6px;
        }

         &::-webkit-scrollbar-thumb {
            background: #D1D1D1;
            border-radius: 6px;
            width: 4px;
        }
    }
`;

export const Day = styled.div`
    width: 37.65px;
    height: 37.65px;
    color: ${props => props.theme.colors.main};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin: 0 2px;
    box-sizing: border-box;
    cursor: pointer;

    &.anotherMonth {
        color: ${ props => props.theme.colors.gray.dark}
    }

    &.disabled {
        color: ${props => props.theme.colors.gray.main}
    }

    &.today {
        color: ${props => props.theme.colors.blue.main}
    }

    &.today.disabled {
        color: ${props => props.theme.colors.blue.main};
        opacity: 50%;
    }

    &.marked {
        background-color:${props => props.theme.colors.gray.main};
        color: ${props => props.theme.colors.blue.main};
    }
    
    &.active {
        background-color: ${props => props.theme.colors.blue.main};
        color: #fff;
    }

    &.active.marked {
        border: 2px solid ${ props => props.theme.colors.main};
        color: #fff;
    }
`;

export const CalendarWrapper = styled.div`
    width: 304px;
    max-width: 304px;
    margin-top: 8px;
    .weekDays {
        display: flex;
        div {
            display: flex;
            justify-content: center;
            flex-basis: 14.2%;
            font-size: 10px;
            letter-spacing: 1.5px;
            color: ${props => props.theme.colors.main};
            font-weight: bold;
        }
    }

   .monthPanel {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

export const ItemKeyDiv = styled.div`
    height:38px;
    width:90px;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.22px;
    cursor: pointer;
    color: ${props => props.theme.colors.main};
    box-sizing: border-box;
    &.true {
        background-color: ${props => props.theme.colors.blue.main};
        color: #fff;
    }

    &.marked {
        background-color: ${props => props.theme.colors.gray.main};
        color: ${props => props.theme.colors.blue.main};
    }

    &.true.marked {
        background-color: ${props => props.theme.colors.blue.main};
        color: #fff;
        border: solid 2px ${props => props.theme.colors.main}
    }
`;

export const PickersWrapper = styled.div`
    display: flex;
    height: 260px;
`;