import { ErrorTypes } from 'src/Logic/actions/errorType/types';
import { setSignInError } from 'src/Logic/actions/errorType/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useResponseHandle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorPageRedirect = (statusCode: number | null, errorType: ErrorTypes) => {
    dispatch(
      setSignInError({
        errorType,
        statusCode,
      }),
    );
    navigate(`/${errorType}`);
  };
  
  return { errorPageRedirect };
};
