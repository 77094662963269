import styled from "styled-components";

export const Wrapper = styled.div`
    margin: ${ p => p.theme.margins.main };
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 20px; 
        font-weight: bold;
        margin-bottom: ${ p => p.theme.margins.main }; 
    }

    .tableContatiner {
        max-width: ${ p => p.theme.sizes.width.mainPage };
        width: 100%;

        .selectBtn {
            width: 80px;
        }
    }

    .message {
        margin-top: ${ p => p.theme.margins.extraLarge };
        max-width: 240px;
        text-align: center;
    }
`;