
const PLUS = '+';
const SPLIT_RANGE = 3;
const SPLIT_RANGE_LARGE = SPLIT_RANGE + 1;


export const replaceNonDigits = (e: string) => {
    return PLUS + e.replace(/\D/g, '');
};

export const formatPhoneNumber = ( value: string, phoneCode: string ) => {
    return value.split('').reduce((acc, current, i) => { 
    if( i <= phoneCode.length - 1 )
        return [ ...acc, current ];
    if ( i === phoneCode.length || 
        i === phoneCode.length + SPLIT_RANGE ||
        i === phoneCode.length + SPLIT_RANGE * 2 ||
        i === phoneCode.length + SPLIT_RANGE * 2 + SPLIT_RANGE_LARGE
        )
        return [ ...acc, ' ', current ];
    return [ ...acc, current ];
    }, [] as string[] ).join('');
}

export const prepareNumberToView = ( value: string, phoneCode: string ) => { 
    return formatPhoneNumber(replaceNonDigits( value ), phoneCode );
}