import React, { useEffect } from 'react';
import styled from 'styled-components';
import ErrorIconSVG from 'src/assets/images/error-icon.svg';
import { CustomErrorComponent } from 'src/components/customErrorComponent/customErrorComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../init/redux';
import { SignInErrorType } from 'src/Logic/actions/errorType/types';
import { AccountDisabledComponent } from './ErrorComponents/accountDisabledComponent';
import { Link } from 'react-router-dom';
import { SELECT_VENUE } from 'src/components/header/constants';
import { setSignInError } from 'src/Logic/actions/errorType/actions';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 246px auto 0;
`;

export const ErrorPage = () => {
  const error = useSelector<AppState, SignInErrorType>(state => state.errorTypeReducer.error);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {   
      dispatch(
      setSignInError({
        errorType: 'default',
        statusCode: null,
      }),
    );}
  }, []);

  switch (error.errorType) {
    case 'account-disabled':
      return (
        <Wrapper>
          <AccountDisabledComponent />
        </Wrapper>
      );

    default:
      return (
        <Wrapper>
          <CustomErrorComponent image = { ErrorIconSVG }>
            <span>Page Not Found</span><br/>
            <span>Click <Link to = { SELECT_VENUE }>here</Link> to go back.</span>
          </CustomErrorComponent>
        </Wrapper>
      );
  }
};
