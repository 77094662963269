import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';
import DeleteImg from 'src/assets/images/delete.svg';
import TrashCan from 'src/assets/images/delete-trash-can.svg';
import CrossSVg from 'src/assets/images/cross.svg';
import { CircleSpinner } from 'src/elements/spinner/circleSpinner';

export const Btn = styled(Button)`
  width: 100%;
  max-width: 130px;
  height: 41px;
  border-radius: 4px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
  border: 2px solid ${props => props.theme.colors.blue.main};
  cursor: pointer;
  background: transparent;
  color: ${props => props.theme.colors.blue.main};

  &:hover:enabled {
    cursor: pointer;
    opacity: 0.8;
    border-color: ${props => props.theme.colors.blue.main};
    background: ${props => props.theme.colors.blue.main};
    color: ${props => props.theme.colors.white.main};
  }

  &:disabled {
    pointer-events: none;
    border-color: transparent;
    background: ${props => props.theme.colors.blue.main};
    opacity: 0.3;
    color: ${props => props.theme.colors.white.main};
  }

  &.none-border {
    border-color: transparent;
    box-shadow: none;

    &:hover {
      border-color: transparent;
    }
  }

  &.small {
    max-width: 80px;
  }

  &.big {
    max-width: 150px;
  }

  &.full {
    max-width: unset;
  }

  &.searchButton {
    min-width: 80px;
    width: 80px;
  }
  
  &.resetButton {
    min-width: 65px;
    width: 65px;
    max-width: fit-content;
  }
`;

type SOFButtonProps = ButtonProps & {
  showSpinner?: boolean,
  children?: React.ReactNode,
}

export const SOFButton = ({ children, showSpinner, ...rest }: SOFButtonProps) => {
  return <Btn { ...rest } >{ showSpinner ? <CircleSpinner style = {{ marginBottom: 3 }}/> : children }</Btn>
}

export const PrimaryBtn = styled(SOFButton)`
  background: ${ props => props.theme.colors.blue.main };
  color: #fff;
  &:hover:enabled {
    background: ${ props => props.theme.colors.blue.main };
    color: #fff;
  }
  &:hover:disabled {
    background: ${props => props.theme.colors.gray.light };
    border: transparent;
    color: ${props => props.theme.colors.gray.main };
  }
`;


export const SecondaryBtn = styled(SOFButton)`
  background: #fff;
  color: ${ props => props.theme.colors.blue.main };
  
  &:hover:enabled {
    background: transparent;
    color: ${ props => props.theme.colors.blue.main };
  }
`;

export const StyledLinkButton = styled.button`
    color: ${ props => props.theme.colors.blue.main};
    font-weight: 700;
    background-color: transparent;
    min-width: fit-content;
    cursor: pointer;
    padding: 0;
    text-transform: capitalize;

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
`;

type LinkButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & { 
  showSpinner?: boolean,
  children?: React.ReactNode,
}
export const LinkButton = ({ showSpinner, children, ...rest}: LinkButtonProps) => { 
  return <StyledLinkButton { ...rest }>{ showSpinner ? <CircleSpinner /> : children }</StyledLinkButton>
}

 const StyledClearButton = styled.div`
    margin: 0 7px;
    min-width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width:14px;

    img {
      width: 100%;
      height: 100%;
    }
    .disabled {
      filter: grayscale(100%);
      opacity: 0.3;
      cursor: default;
    }
`;

type BaseButtonProps = {
    onClick?:() => void, 
    style?: React.CSSProperties, 
    className?: string, 
    disabled?: boolean,
    showSpinner?: boolean;
}

export const ClearButton = ({ onClick, style, className, disabled, ...rest }: BaseButtonProps) => {
    return (
        <StyledClearButton 
            { ...rest } 
            style = { style } 
            className = { className } 
            onClick = {(e) => {e.stopPropagation(); if(onClick && !disabled) onClick()} }
        >
           <img className = {disabled ? 'disabled' : ''} src = {DeleteImg} alt = ''/>
        </StyledClearButton>
    )
}

const StyledTrashButton = styled.div` 

    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      width: auto!important;
    }

    .disabled {
        filter: grayscale(100%);
        opacity: 0.3;
        cursor: default;
    }
`;

export const TrashButton = ({ onClick, style, className, disabled }: BaseButtonProps) => {

  return (
        <StyledTrashButton 
            style = { style } 
            className = { className }
            onClick = {(e) => {e.stopPropagation(); if(onClick && !disabled) onClick()} }
        >
            <img  className = {disabled ? 'disabled' : ''} src = { TrashCan } alt = "Delete" />
        </StyledTrashButton>
    )
}

const StyledImgBtn = styled.div`
    width: 18px;
    height: 18px;
  
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const CrossButton = ({ onClick, style, className, disabled, showSpinner, ...rest }: BaseButtonProps) => {
  return (
    <StyledImgBtn 
      { ...rest } 
      style={style} 
      className = {className} 
      onClick = {(e) => {e.stopPropagation(); if(onClick && !disabled) onClick()} }
    >
    {    showSpinner ? 
            <CircleSpinner /> 
        :
            <img className = {disabled ? 'disabled' : ''} src = {CrossSVg} alt = ''/>
    }
    </StyledImgBtn>
  )
}