import { TechnicalErrorStateType } from "src/Logic/reducers/togglersReducer";

// ----------------------------- Filters Options -----------------------------
export const SET_TECHNICAL_ERROR = 'SET_TECHNICAL_ERROR';
export type SetTechnicalErrorActionType = {
    type: typeof SET_TECHNICAL_ERROR;
    payload:TechnicalErrorStateType;
};

export type SetTechnicalErrorContract = (payload: TechnicalErrorStateType) => SetTechnicalErrorActionType;


export type TogglersActionTypes = 
SetTechnicalErrorActionType                 
