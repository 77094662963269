import { UseQueryResult, UseQueryOptions, QueryKey, QueryFunction, useQuery} from 'react-query';
// import { SOFResponse } from '../models/Response/SOFResponse';
import { AxiosError } from 'axios';
import { useErrorAPIHandle } from './useErrorApi';
import { ErrorResponse } from '../models/Response/ErrorResponse';

type AdditionalSOFQueryOptions = {
        loadingModal?: boolean,
};

export const useSOFQuery = <
    TQueryFnData = unknown,
    TError = AxiosError<ErrorResponse>,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey, 
    queryFn: QueryFunction<TQueryFnData, TQueryKey>, 
    options?: Omit<UseQueryOptions<TQueryFnData, AxiosError<ErrorResponse>, TData, TQueryKey>,
         'queryKey' | 'queryFn'> & AdditionalSOFQueryOptions
): UseQueryResult<TData, AxiosError<ErrorResponse>> => {
    
    const { errorHandle } = useErrorAPIHandle(); 
    let customOnError: ((err: AxiosError<ErrorResponse>,) => void) | undefined;
    if( options ) {
        customOnError = options.onError;
    }
    
    const queryResult = useQuery(queryKey, queryFn, {
        ...options,
        onError: customOnError ? customOnError : ( err ) => {
            errorHandle( err );
        },
     });

    return queryResult;
}