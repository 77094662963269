import { LinkButton, PrimaryBtn } from "src/elements/buttons";
import { Upload } from "src/elements/upload";
import styled from "styled-components"
import UpoadSvg from 'src/assets/images/upload.svg';
import SuccessSvg from 'src/assets/images/complete.svg';

const Container = styled.div`
    width: 521px;
    height: 293px;
    box-sizing: border-box;
    border: dashed 2px ${ p => p.theme.colors.gray.dark }; 
    background-color: ${ p => p.theme.colors.white.main };
    display: flex;
    justify-content: center;

    .interactionPanel {
        display: flex;
        flex-direction: column;
        margin-top: 52px;
        align-items: center;

        img {
            width: 82px;
        }

        .ant-btn {
            width: 164px;
            margin-top: 25px;
            max-width: unset;
        }
        
        .failedUploadsWrapper {
            text-align: center;

            .failedUploads {
                color: ${ p => p.theme.colors.red.main };
            }
        }
        
        .infoText {
            margin-top: 10px;
        }
    }
`;

type ResultType = {
    uploaded: number | null,
    failed: number | null,
    stopped: number | null,
}

type Props = {
    completed: boolean,
    result: ResultType | null, 
    onFilesAdd: ( files: FileList ) => void,
    onReupload: () => void,
    disabled: boolean,
};

export const FileAccepter = ({ completed, result, onFilesAdd, onReupload, disabled }: Props ) => {

    const notLoadedMessage = `${ result?.failed ? `${ result.failed } photos failed to upload.` 
    : ''}${ result?.stopped ? ` ${ result.stopped } photos stopped.` : ''}`

    return (
        <Upload action = { onFilesAdd } disabled = { disabled } >
            <Container>
                <div className = 'interactionPanel' >
                    <img src = { completed ? SuccessSvg : UpoadSvg } alt = 'Upload' />
                    { 
                        !completed ?
                            <>
                                <PrimaryBtn>Upload Photos</PrimaryBtn>
                                <span className = 'infoText' >or drag and drop</span>
                            </>
                        :
                            <>
                                <span className = 'infoText'>Uploading Completed.</span>
                                {  !!result?.uploaded && 
                                    <span>{ result?.uploaded } photos were uploaded successfully.</span>
                                }
                                {  (!!result?.failed || !!result?.stopped ) && 
                                    <span className = 'failedUploadsWrapper' >
                                        <span className = "failedUploads">
                                          { notLoadedMessage } 
                                        </span>
                                        <br />
                                            &nbsp;Reupload failed or stopped photos &nbsp;
                                            <LinkButton 
                                                onClick = {(e) => { 
                                                    e.stopPropagation();
                                                    onReupload();
                                                }} 
                                                style = {{ textTransform: 'none'}}
                                            >here</LinkButton>
                                    </span>
                                }
                            </>
                    }
                </div>
            </Container>
        </Upload>
    )
}