export const SELECT_VENUE_EVENT = '/select-venue-event' ;
export const SELECT_VENUE = '/select-venue' ;
export const UPLOAD_PHOTO = '/photo-upload' ;
export const PHONE_NUMBER = '/phone-number' ;
export const VARIFICATION_CODE = '/verification-code' ;
export const ACCOUNT_DISABLED = '/account-disabled' ;
export const NOT_FOUND = '/not-found' ;
export const BASE = '/' ;



export const PREV_PAGE = -1;
