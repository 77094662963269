// Core
import { useRef, useState } from "react";

// Styles
import { ItemKeyDiv, TimeList, TimePickerWrapper } from "./styles";

// Svg
import downArrow from '../../assets/images/down-arrow.svg';
import upArrowGray from '../../assets/images/up-arrow-gray.svg';

const TimeItem = ({time, active, index, marked, onClick}:
     {time:string, active:boolean, index:number, marked:boolean, onClick: (index: number) => void,}) => {

    return (
        <li onClick={() => {onClick(index)}}>
            <ItemKeyDiv className = {`${active}${marked ? ' marked' : ''}`}>
                {time}
            </ItemKeyDiv>
        </li>
    );
}

type Props = {
    pickedTime:  number,
    pickedDate: moment.Moment | undefined,
    setPickedTime:  React.Dispatch<React.SetStateAction<number>>,
    setClickedOn: React.Dispatch<React.SetStateAction<{
        date: boolean;
        time: boolean;
    }>>,
    disabledTime?: (time: number) => boolean,
    markedTime?: ( time: number) => boolean,
    availableTime: string[],
}

export const TimePicker = ({availableTime, pickedTime, setPickedTime, setClickedOn, disabledTime, markedTime}:Props) => {

    const [ mouseOnList, setMouseOnList ] = useState(false);
    const timeListRef = useRef<HTMLDivElement>(null);
    const scrollThumbRef = useRef<HTMLDivElement>(null);
  
    const scrollTimePicker = (up: boolean) => {

        const listItemsPositions = [0];
        const scrollBarItemPositions = [0];
            //  @ts-ignore:
        timeListRef.current?.childNodes.forEach((child, key) => {listItemsPositions[key] = child.offsetTop;})
            //  @ts-ignore:
        scrollThumbRef.current?.childNodes.forEach((child, key) => {scrollBarItemPositions[key] = child.offsetTop;})
            
        if(up) {
            if(pickedTime - 1 >= 0) {
                setPickedTime(pickedTime - 1);
                timeListRef.current?.scrollTo({top: listItemsPositions[pickedTime - 3], behavior: 'smooth'})
                scrollThumbRef.current?.scrollTo({top:  scrollBarItemPositions[pickedTime - 3], behavior: 'smooth'})
            }
        }
        else
            if(pickedTime + 1 < availableTime.length) {
                setPickedTime(pickedTime + 1)
                timeListRef.current?.scrollTo({top: listItemsPositions[pickedTime - 1], behavior: 'smooth'})
                scrollThumbRef.current?.scrollTo({top: scrollBarItemPositions[pickedTime - 1], behavior: 'smooth'})
            }
    }
 
    const handleTimePick = (index:number) => {
        setClickedOn((prevState) => {
            const newState = {...prevState};
            newState.time = true;   
            return newState;
        })
        setPickedTime(index + (24 - availableTime.length));
    }

    const onTimePickerScroll = () => {
        if(timeListRef.current && mouseOnList) {

        // Difference between listScrollHeight and scrollBarHeight;
        const percentageDifference = 19.96;
        let calculatedScrollHeight = 0;
        
        calculatedScrollHeight = timeListRef.current?.scrollTop + (timeListRef.current?.scrollTop * (percentageDifference / 100));

        scrollThumbRef.current?.scrollTo({top:calculatedScrollHeight});
        }
    }

    const  onScrollBarScroll = () => {
        if(scrollThumbRef.current && !mouseOnList) {
            const percentageDifference = 19.96;
            let calculatedScrollHeight = 0;
            calculatedScrollHeight = scrollThumbRef.current?.scrollTop - (scrollThumbRef.current?.scrollTop * (percentageDifference / 100));

            timeListRef.current?.scrollTo({top: calculatedScrollHeight})
        }
    }

    const handleMarkedTimeCheck = (index: number) => {
        if(markedTime)
        {
            const indexDifference = 24 - availableTime.length;
            return  markedTime(index + indexDifference);
        }
        return false;
    }
    return (
        <TimePickerWrapper>
            <TimeList >
                <div 
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}} 
                    onMouseEnter={() => {setMouseOnList(true)}} 
                    onMouseLeave={() => {setMouseOnList(false)}}>
                        <img 
                            onClick = {() => {scrollTimePicker(true)}} 
                            src={pickedTime === 0 ? upArrowGray : downArrow} 
                            alt = '' 
                            style = {{transform: `rotate(${pickedTime === 0 ? 0 : 180}deg)`, marginBottom: '10px'}}/>
                        <div className='scrollableList'  ref = {timeListRef} onScroll = {onTimePickerScroll}>
                            {availableTime.map((hour, index) => {
                                return <TimeItem 
                                            marked = {handleMarkedTimeCheck(index)}
                                            key = {hour} 
                                            index = {index}
                                            time = {hour} 
                                            active={ pickedTime === index} 
                                            onClick={handleTimePick}
                                        />})}
                        </div>
                        <img 
                            onClick = {() => {scrollTimePicker(false)}} 
                            src={pickedTime === availableTime.length - 1 ? upArrowGray : downArrow} 
                            alt = ''  
                            style ={{marginTop: '10px', transform: `rotate(${pickedTime === availableTime.length - 1 ? 180 : 0}deg)`}}
                        />
                    </div>
                    <div className = 'timePickerScrollBar' ref={scrollThumbRef} onScroll = {onScrollBarScroll}>
                        {availableTime.map((h) => {return <div key = {h} style={{height: '45.6px'}}/>})}
                    </div>
                    </TimeList>
        </TimePickerWrapper>
    )
}

