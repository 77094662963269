import { Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SELECT_VENUE, UPLOAD_PHOTO } from "src/components/header/constants";
import { LoadingSpinner } from "src/components/loadingSpinner/loadingSpinner";
import { PrimaryBtn } from "src/elements/buttons";
import { useUploadData } from "src/Logic/hooks/useUploadData";
import { useVenueEventsQuery } from "src/Logic/hooks/VenueEvents/useVenueEventsQuery";
import * as s from "./styles";
import { TableColumnSettings } from "./columnSettings";
import { SelectEventFilterPanel } from "./selectEventFilterPanel/selectEventFilterPanel";

type PageModes = 'Select' | 'Error' | 'Loading';
export type FormDataType = {
    venueEventId: string | undefined,
    searchText: string | undefined,
    date: string,
}

export const SelectVenueEventPage = () => {

    const [ columnSettings, setColumnSettings ] = useState([ ...TableColumnSettings ]);
    const [ pageMode, setPageMode ] = useState<PageModes>('Loading')
    const [ form ] = useForm<FormDataType>();

    const navigate = useNavigate();
    const { setEventId } = useUploadData();
    const { isLoading, searchEvents, venueEvents, venueId, isError } = useVenueEventsQuery( form );

    useEffect(() => {
        if( venueId === null )
            navigate( SELECT_VENUE, { replace: true });
    }, [ venueId ])

    useEffect(() => {
        setColumnSettings(( prevSettings ) => { 
            const newSettings = [ ...prevSettings ];

            newSettings[ newSettings.length - 1 ].render = ( id: number ) => {
                return ( 
                    <div>
                        <PrimaryBtn
                            qa-data-id = 'select-button'
                            className = "selectBtn"
                            onClick = {() => { 
                                setEventId( id );
                                navigate( UPLOAD_PHOTO );
                            }}
                        >Select</PrimaryBtn>
                    </div>
                )
            }

            return newSettings;
        })

    }, [ venueEvents, isError ]);

    useEffect(() => {
        if( isLoading )
            setPageMode('Loading');
        else if( venueEvents ) {
            if( venueEvents.length > 0)
                setPageMode('Select');
            else
                setPageMode('Error')
        } 
        if( isError ) 
            setPageMode('Error')
     }, [ venueEvents, isError, isLoading ] )
    
    const renderContent = () => {
        switch( pageMode ) {
            case 'Select': {
                return (
                    <div className = 'tableContatiner mtMain' >
                        <Table 
                            dataSource = { venueEvents?.map(( e ) => {
                                return {
                                    id: e.id,
                                    name: e.name,
                                    startDate: moment(e.startTimeET).format('MM/DD/YYYY'),
                                    startTime: `${moment(e.startTimeET).format('hh:mm A')} ET`,
                                    action: e.id,
                                }
                            }) }
                            rowKey = { r => r.id }
                            columns = { columnSettings }
                            className = 'dashboardTable  selectEventTable'
                            pagination = { false }
                        />
                    </div>
                )
            }   
            case 'Error': {
                return ( 
                    <span className = "message">Sorry, no events found.</span>
                );
            }
            case 'Loading': {
                return (
                    <div className = "d-flex flex-column align-items-center">
                        <LoadingSpinner loadingText = "Loading venue events..." />
                    </div>
                )
            }
        }
    }

    return (
        <s.Wrapper>
            <span className = "title" >Select Event</span>
            <SelectEventFilterPanel onSearch = { searchEvents } form = { form } />
            { renderContent() }
        </s.Wrapper>
    );
}