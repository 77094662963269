import API from 'src/Logic/API/API';
import { VenueEvent } from 'src/Logic/models/General/VenueEvent';
import { ErrorResponse } from 'src/Logic/models/Response/ErrorResponse';
import { FormDataType } from 'src/pages/SelectVenueEvent';

type Props = FormDataType & {
    nextTimeStemp?: string,
    venueId: number,
}

export const getVenueEvents = ({ venueId, ...params }: Props ) => {
    return API.get<VenueEventsResponseType>(`api/venues/${ venueId }/venueEvents`, { params: {
        ...params,
    }});
};

export type VenueEventsResponseType = ErrorResponse & {
    venueEvents: VenueEvent[],
}