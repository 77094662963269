import VerificationInput from 'react-verification-input';
import { useCallback, useEffect, useState } from 'react';
import * as s  from 'src/pages/SignIn/VerificationCodeWithSignIn/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePhoneNumberMutation } from 'src/Logic/hooks/SignIn/usePhoneNumberMutation';
import { CircleSpinner } from 'src/elements/spinner/circleSpinner';
import { AxiosError, AxiosResponse } from 'axios';
import { setRequestsLimitError } from 'src/Logic/actions/errorType/actions';
import { useDispatch } from 'react-redux';
import { VerificationParamsFromURLType } from 'src/pages/SignIn/VerificationCodeWithSignIn/types';
import { LogoComponent } from 'src/components/logo/logoComponent';
import { useErrorAPIHandle } from 'src/Logic/hooks/useErrorApi';
import { ResponsePhoneNumberType } from 'src/Logic/API/Verification/SendPhoneNumberPost/types';
import { useSignInAndCode } from 'src/Logic/hooks/SignIn/useSignIn';
import EditPhoneNumberIcon from 'src/assets/images/edit-icon.svg';
import { PHONE_NUMBER } from 'src/components/header/constants';
import { prepareNumberToView } from 'src/components/phoneSelectInput/helpers';

const CORRECT_CODE_LENGTH = 6;

export const VerificationCodeWithSignIn = () => {
    const dispatch = useDispatch();

    const [code, setCode] = useState<string>('');
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const isActive = code || isFocus;

    const navigate = useNavigate();
    const location = useLocation();
    const { state: phoneState } = location as VerificationParamsFromURLType;
    const  [requestIdFromRequest, setRequestIdFromRequest] = useState<string | null>(null);

    const {
        sendVerificationCode,
        sendVerificationCodeLoading,
        isSignInLoading,
        resetVarificationError,
        varificationError,
        isVarificationError,
    } = useSignInAndCode();

    const { errorHandle } = useErrorAPIHandle();

    const {
        isLoading: sendPhoneNumberLoading,
        mutateAsync: resendPhone,
    } = usePhoneNumberMutation({
        onError: (error: AxiosError<ResponsePhoneNumberType>) => {
            const status = error.response ? error.response.status : null;

            if (status === 429) {
                // const { phoneNumber, countryCode } = phoneState;
                dispatch(setRequestsLimitError({ requestsLimitError: true }));
                navigate( PHONE_NUMBER )
                return;
            }

            errorHandle(error);
        },
        onSuccess: (response: AxiosResponse<ResponsePhoneNumberType>) => {
            const requestId = response.data.requestId;
            setRequestIdFromRequest(requestId);
        }
    });

    const isLoading = sendPhoneNumberLoading || sendVerificationCodeLoading || isSignInLoading ;

    useEffect(() => {
        if (!phoneState) {
            return navigate( PHONE_NUMBER );
        }
    }, [phoneState]);

    const changeValue = useCallback(
        (phoneCode: string) => {
        if (varificationError)
            resetVarificationError();

        setCode(phoneCode);
        if (phoneCode.length === CORRECT_CODE_LENGTH)
            sendCode(phoneCode);
        },
        [varificationError, requestIdFromRequest],
    );

    useEffect(() => {
        isVarificationError && setCode('')
    }, [ isVarificationError ])

    const changeFocusOn = useCallback(() => {
        setIsFocus(true);
    }, [ isFocus ]);

    const changeFocusOff = useCallback(() => {
        setIsFocus(false);
    }, [ isFocus ]);

    const sendCode = async ( phoneCode: string ) => {
        const requestId = requestIdFromRequest || phoneState.requestId;
        await sendVerificationCode({
            verificationCode: phoneCode,
            requestId,
        });
    };

    const resendCode = async () => {
        if (varificationError)
            resetVarificationError();

        setCode('');
        const { phoneNumber, countryCode } = phoneState;
        await resendPhone({ phoneNumber, countryCode });
    };

    const editPhoneNumber = () => {
        navigate( PHONE_NUMBER );
    }

    return (
        <s.Wrapper>
            <div className={`authContentWrapper`}>
                <LogoComponent className = 'authLogo'/>

                <div className={`phoneTextWrapper`}>
                <div className={`bold-text sendText`}>Code was sent to {
                    phoneState?.phoneNumber && prepareNumberToView( phoneState?.phoneNumber, phoneState.countryCode )
                }</div>
                <button className={`reset-button`} onClick={editPhoneNumber}>
                    <img src={EditPhoneNumberIcon} alt="Edit" />
                </button>
                </div>

                {isLoading ? (
                <s.Spinner>
                    <CircleSpinner className={`greenSpinner`} />
                </s.Spinner>
                ) : (
                <div>
                    <s.Div>
                    <VerificationInput
                        autoFocus
                        length={CORRECT_CODE_LENGTH}
                        value={code}
                        onChange={changeValue}
                        removeDefaultStyles
                        onFocus={changeFocusOn}
                        onBlur={changeFocusOff}
                        classNames={{
                        container: 'container',
                        character: `character ${varificationError ? 'error-border' : isActive ? 'active' : ''} `,
                        characterSelected: `character--selected ${
                            code.length === CORRECT_CODE_LENGTH ? 'displayCursor' : ''
                        }`,
                        }}
                        placeholder={` `}
                    />

                    <span className={`error standart-error-text ${varificationError ? 'errorTextTrue' : null}`}>
                        {varificationError}
                    </span>
                    </s.Div>

                    <div className={`bold-text resendCodeWrapper`}>
                    <span className={`resendCode`} onClick={resendCode}>
                        Resend Code
                    </span>
                    </div>
                </div>
                )}
            </div>
        </s.Wrapper>
    );
};
