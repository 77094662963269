// Core
import { useEffect, useState } from "react";

// Styles
import { CalendarWrapper, DatePickerWrapper, Day, Header } from "./styles"

// Moment
import moment from "moment-timezone";

// Svg
import paginationLeftArrow from '../../assets/images/pagination-left-arrow.svg'
import paginationLeftDoubleArrow from '../../assets/images/left-double-arrow.svg'

// Helpers
import { prepareDaysToDisplay } from "./helpers/prepareDaysToDisplay";

//  ------------------ Day -----------------
type DayProps = {
    active: boolean,
    date: moment.Moment,
    onClick: (date: moment.Moment) => void,
    index: number;
    additionalMarked: boolean;
    disabled?: boolean
    anotherMonth: boolean,
    className?: string | undefined;
}
 
const CalendarDay = ({
    active, 
    onClick, 
    date, 
    additionalMarked, 
    disabled, 
    anotherMonth,
    className,
}:DayProps) => {
    const isToday = moment().format('DD-MM-YYYY') === date.format('DD-MM-YYYY');

    const  handleClick = () => {
        if(!disabled)
            onClick(date);
    }

    return (
            <Day 
                onClick = {handleClick} 
                className = {`${isToday ? 'today' : ''}${active ? ' active' : ''}${
                    disabled ? ' disabled' : '' }${additionalMarked ? ' marked' : ''}${
                    anotherMonth ? ' anotherMonth' : ''}${className ? ` ${ className }` : ''}` }>
                {date.date()}
            </Day>
    )
}

type Props = {
    pickedDate:  moment.Moment | undefined,
    setPickedDate: ( date: moment.Moment | undefined ) => void,
    setClickedOn: React.Dispatch<React.SetStateAction<{
        date: boolean;
        time: boolean;
    }>>
    markedDate?: moment.Moment | string;
    disabledDate?: (date:moment.Moment) => boolean;
    localTimeZone?: boolean,
    dateClassName?: (date: moment.Moment) => string | undefined,
}

export const DatePicker = ({ 
    pickedDate, 
    setPickedDate, 
    setClickedOn, 
    markedDate, 
    disabledDate, 
    localTimeZone,
    dateClassName,
 }: Props) => {

    const [ currentDate, ] = useState( localTimeZone ? moment() : moment().tz('America/New_York', true));
    const [ displayedDate, setDisplayedDate ] = useState(pickedDate ? pickedDate.clone() :currentDate.clone());
    const [ additionalMarkedDate, setAdditionalMarkedDate ] = useState<moment.Moment | undefined>(
        markedDate !== undefined ? (typeof markedDate === 'string' ? moment(markedDate) : markedDate) : undefined);

        
    useEffect(() => {
        setDisplayedDate(pickedDate ? pickedDate.clone() : currentDate.clone());
    },[ pickedDate ])

    useEffect(() => {
        setAdditionalMarkedDate(markedDate !== undefined ? (typeof markedDate === 'string' ? moment(markedDate) : markedDate) : undefined)
    }, [ markedDate ])
    
    const visibleDates = prepareDaysToDisplay(displayedDate);
    
    const onDayChoose = (value:any) => {
        setClickedOn((prevState) => {
            const newState = {...prevState};
            newState.date = true;
            return newState;
        })
        if(value)
            setPickedDate(value);
    }

    const changeYear = (direction: boolean) => {
        if(direction)
            setDisplayedDate(displayedDate.add(1, "year").clone());
        else
            setDisplayedDate(displayedDate.subtract(1, "year").clone());
    }

    const changeMonth = (direction:boolean) => {
        if(direction)
            setDisplayedDate(displayedDate.add(1, "month").clone());
        else
            setDisplayedDate(displayedDate.subtract(1, "month").clone());
    }

    return (
        <DatePickerWrapper>
            <Header>
                <div>
                    <img onClick = {() => {changeYear(false)}} src={paginationLeftDoubleArrow} alt = '' style={{marginRight: '15px'}}/>
                    <img onClick = {() => {changeMonth(false)}} src={paginationLeftArrow} alt = ''/>
                </div>
                <span>{`${displayedDate.format('MMMM')} ${displayedDate.year()}`}</span>
                <div>
                    <img onClick = {() => {changeMonth(true)}} src={paginationLeftArrow} alt = '' style={{transform: 'rotate(180deg)', marginRight: '15px'}}/>
                    <img onClick = {() => {changeYear(true)}} src={paginationLeftDoubleArrow} alt = '' style={{transform: 'rotate(180deg)'}}/>      
                </div>
            </Header>
            <CalendarWrapper>
                <div className = 'weekDays'>
                    <div>SUN</div>
                    <div>MON</div>
                    <div>TUE</div>
                    <div>WED</div>
                    <div>THU</div>
                    <div>FRI</div>
                    <div>SAT</div>
                </div>
                    <div className = 'monthPanel'>
                        {visibleDates.map((date, index) => {
                            return ( <CalendarDay 
                                        key={index} 
                                        index = {index} 
                                        className = { dateClassName ? dateClassName(date) : undefined}
                                        date = {date} 
                                        active = {pickedDate ? pickedDate.format('DD:MM:YY') === date.format('DD:MM:YY') : false} 
                                        onClick = {onDayChoose}
                                        additionalMarked = {additionalMarkedDate ? additionalMarkedDate.format('DD:MM:YY') === date.format('DD:MM:YY') : false}
                                        disabled = {disabledDate ? disabledDate(date) : undefined}
                                        anotherMonth = { !displayedDate.isSame(date, "month") }
                                        />
                                    )})}
                    </div>
                    </CalendarWrapper>
        </DatePickerWrapper>
    )
}