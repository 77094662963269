import { AxiosResponse } from 'axios';
import API from 'src/Logic/API/API';
import { ErrorResponse } from 'src/Logic/models/Response/ErrorResponse';

type Props = {
    venueEventId: number,
}

export const stopPhotoUploading = ( { venueEventId }: Props ) => {
  return API.post<AxiosResponse<ErrorResponse>>(`api/venueEvents/${ venueEventId }/upload/stop`);
};