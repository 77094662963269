// Core
import { useEffect,  useRef, useState } from "react";

// Styles
import { PickerPopup, PickersWrapper, PickerWrapper } from "./styles";

// Components
import { TimePicker } from "./timePicker";
import { DatePicker } from "./datePicker";

// Elements
import { ClearButton } from "src/elements/buttons";

// Moment
import moment from "moment-timezone";

type Props = {
    onChange?: (date:any) => void;
    value?: moment.Moment | string | undefined,
    placeholder?: string,
    className?: string,
    markedDate?: moment.Moment | string,
    disabledDate?: (date: moment.Moment) => boolean
    manualyOpened?: boolean,
    disabledTime?: (date: moment.Moment | undefined, time: number) => boolean,
    markedTime?: (date: moment.Moment | undefined, time: number) => boolean,
    timePicker?: boolean,
    style?: React.CSSProperties | undefined,
    pickedQaId?: string,
    localTimeZone?: boolean,
    dateClassName?: (date: moment.Moment) => string | undefined,
}

const hours = ['00:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM',  '9:00 AM', '10:00 AM', '11:00 AM',
    '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM']

const CALENDER_POPUP_SECTION_WIDTH = 340;
const TIME_POPUP_SECTION_WIDTH = 142;

export const DateTimePicker = ({
    value, 
    onChange, 
    placeholder, 
    className, 
    markedDate, 
    disabledDate, 
    manualyOpened, 
    disabledTime, 
    markedTime, 
    timePicker, 
    style,
    pickedQaId,
    localTimeZone,
    dateClassName,
    ...rest
}:Props) => {

    const [ pickerOpened, setPickerOpened ] = useState(Boolean(manualyOpened));
    const [ popUpLeftPosition, setPopUpLeftPosition ] = useState(true);
    const [ pickedDate, setPickedDate ] = useState<moment.Moment | undefined>();
    const [ pickedTime, setPickedTime ] = useState(pickedDate ? pickedDate.hour() : 0);
    const [ clickedOn, setClickedOn ] = useState({date: false, time: false});
    const [ availableTime, setAvailebelTime ] = useState(hours);
    const pickerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    //  --------------- Initialization ----------------
    useEffect(() => {
        document.addEventListener('click', handleClosePicker);
        return () => {document.removeEventListener('click', handleClosePicker)}
    }, [onChange, pickedTime, pickedDate, pickerOpened, value]);
    
    useEffect(() => {
        checkPopUpPosition();
        window.addEventListener('resize', checkPopUpPosition);
        return () => {window.removeEventListener('resize', checkPopUpPosition)}
    }, [])
    
      useEffect(() => {
        if(disabledTime) {
          setAvailebelTime(hours.filter((hour, index) => {return disabledTime(pickedDate, index)}))
        }
    }, [pickedDate, value, disabledTime])

    useEffect(() => {
        let momentValue: moment.Moment | undefined = undefined;
        if( value ) {
            if( typeof value === 'string') 
                    momentValue = moment(value)
                else   
                    momentValue = moment(value.clone());
        }
        if( !localTimeZone )
           momentValue = momentValue?.tz( 'America/New_York' );

        setPickedDate( momentValue );
        setPickedTime( momentValue && timePicker ? momentValue.hour() : 0);
    }, [value])

    const checkPopUpPosition = () => {
        const pickerRectData = pickerRef.current?.getClientRects()[0];
        const bodyWidth = document.body.offsetWidth;
        let popupWidth = CALENDER_POPUP_SECTION_WIDTH;
        if(timePicker)
            popupWidth = TIME_POPUP_SECTION_WIDTH + CALENDER_POPUP_SECTION_WIDTH;
        if(pickerRectData && pickerRectData?.x + popupWidth >= bodyWidth)
            setPopUpLeftPosition(false);
        else
            setPopUpLeftPosition(true);
    }
    
    useEffect(() => {
        setPickerOpened(Boolean(manualyOpened));
    }, [ manualyOpened ])
    
    useEffect(() => {
        if(timePicker && clickedOn.date && clickedOn.time) {
            setClickedOn({date: false, time: false});
            setPickerOpened(false);
            handleSubmit();
        }
        else if(!timePicker && clickedOn.date) {
            setClickedOn({date: false, time: false});
            setPickerOpened(false);
            handleSubmit();
        }
    },[clickedOn])
    
    // ------------------- Handlers ------------------------------------
    // ------------------- Sumbit Date/Time selection -------------------
    const handleSubmit = () => {
        if( onChange && pickedDate ) {   
            let choosedDateTime = pickedDate.clone();
            if( !localTimeZone )
                choosedDateTime = pickedDate.tz( 'America/New_York', true );
            choosedDateTime.hours( pickedTime );
            choosedDateTime.minute( 0 );
            onChange(choosedDateTime.format());
        }
    }
    
    // ------------------- Open Picker -------------------
    const handleOpenPicker = () => {
        setPickerOpened(true);
    }

    // ------------------- Close Picker -------------------
    const handleClosePicker = (e: any ) => {
        if (pickerRef.current && !pickerRef.current.contains(e.target) && pickerOpened) {
            setClickedOn({date: false, time: false});
            handleSubmit();
            setPickerOpened(false);
        }
    }

    // ------------------- Check Is Time Disabled -------------------
    const handleDisabledTime = (time:number) => {
        if(disabledTime && pickedDate)
            return disabledTime(pickedDate, time);
        return true;
    }

    // ------------------- Check Is Time Marked -------------------
    const handleMarkedTime = (time:number) => {
        if(markedTime && pickedDate)
            return markedTime(pickedDate, time);
        return false;
    }

    // REVIEW: is this method needed
    const hanldeSetPickedDate = ( date: moment.Moment | undefined ) => {
        setPickedDate( date?.set('hour', pickedTime ));
    }

    // ------------------- Clear picked time -------------------
    const handleClearData = () => {
        setPickedDate( undefined );
        setPickedTime( 0 );
        onChange && onChange( undefined );
    }

    const handleQAInputValueChange = (e: React.ChangeEvent<HTMLInputElement> ) => {
        if( e.target.value && moment( e.target.value ).isValid() ) { 
            const momentValueFromInput = moment.parseZone( e.target.value );
            hanldeSetPickedDate( momentValueFromInput );
            setPickedTime( momentValueFromInput.hours() );
            handleSubmit();
        }
        else handleClearData();
    }

    return (
        <PickerWrapper 
            { ...rest }
            ref = { pickerRef }
            style = { style }
            className = {`${pickerOpened ? 'focused': ''} ${ className ? className : '' }`} 
            onClick = { handleOpenPicker }
        >
            <input 
                ref = { inputRef }
                onChange = { handleQAInputValueChange }
                className = 'pickerQaInput' 
                // value = { timePicker ? pickedDate?.format('MM/DD/YYYY HH:mm') : pickedDate?.format('MM/DD/YYYY') } />
                value = { pickedDate?.toISOString() } 
            />
            <div className = 'inputWrapper'>
                <div className = {`placeholder ${pickedDate ? 'picked' : ''}`}>{placeholder}</div>
                <div 
                    data-qa-id = { pickedQaId ? pickedQaId : ''}
                    className = {`inputDataDisplayer ${pickedDate ? 'picked' : ''}${ 
                        placeholder ? ' withPlaceholder' : ''}`}
                >{ pickedDate ? timePicker ? pickedDate.format('MM/DD/YYYY HH:mm') : pickedDate.format('MM/DD/YYYY') : '' }</div> 
                { pickedDate ? <ClearButton onClick = { handleClearData } className = 'pickerClearBtn' /> : null }
            </div>
            <PickerPopup 
                leftPosition = { popUpLeftPosition } 
                className = {`${pickerOpened ? '' : 'pickerHidden'}${timePicker ? '' : ' thin'}`}
            >
                <PickersWrapper>
                    <DatePicker 
                        pickedDate = {pickedDate} 
                        setPickedDate = {hanldeSetPickedDate} 
                        setClickedOn = {setClickedOn} 
                        markedDate = {markedDate} 
                        localTimeZone = { localTimeZone }
                        disabledDate = {disabledDate}
                        dateClassName = { dateClassName }
                        />
                    { timePicker ? 
                    <>
                        <div className = 'pickerDivider' />
                        <TimePicker 
                            availableTime = {availableTime}
                            pickedDate = {pickedDate} 
                            disabledTime = {disabledTime ? handleDisabledTime : undefined} 
                            pickedTime = {pickedTime - 24 + availableTime.length} 
                            setPickedTime = {setPickedTime} 
                            markedTime = {handleMarkedTime}
                            setClickedOn = {setClickedOn}
                        />
                    </>
                    : null 
                    }
                </PickersWrapper>
            </PickerPopup>
        </PickerWrapper>
    )
}

