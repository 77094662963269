import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { PHONE_NUMBER } from "src/components/header/constants";
import { QueryKeys } from "src/init/reactQuery";
import { signOut as signOutRequest } from "src/Logic/API/Profile/signOut"
import { useSOFQuery } from "../useSOFQuery"

export const useSignOutQuery = () => {
    
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { refetch, isRefetching, isLoading, isSuccess, } = useSOFQuery( QueryKeys.SignOut, signOutRequest, { 
        enabled: false,
        onSuccess: () => {
            navigate( PHONE_NUMBER );
            queryClient.removeQueries( QueryKeys.Venues );
            queryClient.removeQueries( QueryKeys.VenueEvents );
        }
    });

    const signOut = () => {
        queryClient.resetQueries([ QueryKeys.SignOut ]);
        refetch();
    }
    return { 
        signOut,
        signOutLoading: isRefetching || isLoading,
        isSuccess,
        queryClient,
    };
}