import { useDispatch } from 'react-redux';
import { setTechnicalErrorAction } from '../actions/togglers';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../models/Response/ErrorResponse';
import { PHONE_NUMBER } from 'src/components/header/constants';
import { useResponseHandle } from './useAuthTecnicalError';

// enum TokenErrorCodes {
//     AbsentOrInvalidAuthToken = 'absentOrInvalidAuthToken',
//     InvalidAuthToken = 'invalidAuthToken',
//     AuthTokenExpired = 'authTokenExpired',
// }

export const useErrorAPIHandle = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { errorPageRedirect } = useResponseHandle();

    const notSignedInHandle = () => {
        navigate( PHONE_NUMBER );
    }

  const errorHandle = async (error: AxiosError<ErrorResponse>) => {
    const status = error.response ? error.response.status : null;
    // const tokenErrorCodes = [
    //     TokenErrorCodes.AbsentOrInvalidAuthToken,
    //     TokenErrorCodes.AuthTokenExpired,
    //     TokenErrorCodes.InvalidAuthToken,
    // ] as string[];
    
    if (!status) {
        dispatch(
            setTechnicalErrorAction({
                isError: true,
                title: 'No Internet Connection',
                message: 'Please try again when your connection is available.',
            }),
            );
            return;
        }
        
    if (status === 401) {
        // TODO: IS token codes check necessary ?
        // const errorCode = error.response?.data.errorCode;
        // if( errorCode && tokenErrorCodes.includes( errorCode )){
        //     notSignedInHandle();
        //     return;
        // }

        notSignedInHandle();
        return;
    }

    if( status === 403 ) {
        errorPageRedirect(status, 'account-disabled');
        return
    }

    if( status === 404 ) {
        navigate( PHONE_NUMBER );
        return;
    }

    dispatch(
      setTechnicalErrorAction({
        isError: true,
        title: 'Technical Error',
        message: 'Please try again',
      }),
    );
  };

  return { errorHandle };
};
