import styled from 'styled-components';

export const Panel = styled.div`
  max-width: 1174px;

  .filterPanelForm {
    display: flex;
    .largeFilter {
      width: 240px;
    }

    .mediumFilter {
      width: 130px;
    }

    .pastDate {
      color: ${ p => p.theme.colors.gray.dark };
    }
  }
`;

export const Img = styled.img`
  position: absolute;
  top: 31px;
  left: 17px;
`;

export const Div = styled.div`
  max-height: 40px;
  display: flex;
  align-items: flex-end;
`;
