import { useRef } from "react"
import styled from "styled-components";

const Div = styled.div`
    width: fit-content;
    display: contents;
    position: relative;
    &.disabled {
        opacity: 0.4;
        cursor: default;
    }

    input {
        display: none!important;
    }
`;

type Props = {
    children?: any,
    action?: ( files: FileList ) => void,
    disabled?: boolean,
}

export const Upload = ({ action, children, disabled }: Props) => {

    const inputRef = useRef<HTMLInputElement>( null );

    const handleClick = () => {
        !disabled && inputRef.current && inputRef.current.click();
    }

    const handleFileChange = ( e: React.ChangeEvent<HTMLInputElement>) => {
        if( e.target.value && e.target.files )
            handleAction( e.target.files );
        // Needed for allowing to select already selected files 
        e.target.value = '';
    }

    const handleDragOver = ( e: React.DragEvent<HTMLDivElement> ) => {
        e.preventDefault();
    }

    const handleDrop = ( e: React.DragEvent<HTMLDivElement> ) => {
        e.preventDefault();
        e.stopPropagation();
        if( e.dataTransfer?.files && e.dataTransfer.files.length > 0 ) {
            handleAction( e.dataTransfer.files );
        }
        console.log( e.target )
        
    }

    const handleAction = ( files: FileList ) => { 
        if( action && !disabled ) {
            action( files );
        }
    }

    return ( 
        <Div 
            onDrop = { handleDrop } 
            onDragOver = { handleDragOver } 
            onClick = { handleClick } 
            className = { disabled ? ' disabled' : '' }
        >
            <input ref = { inputRef } multiple type = 'file' onChange = { handleFileChange } />
            { children }
        </Div>
    );
}