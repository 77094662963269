import { ReactComponent as PhotoEngineLogo } from 'src/assets/images/photo-upload.svg';
import { ReactComponent as BackArrow } from 'src/assets/images/back-arrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { 
    SELECT_VENUE_EVENT,
    PREV_PAGE,
    UPLOAD_PHOTO,
    PHONE_NUMBER,
    VARIFICATION_CODE,
    NOT_FOUND,
    BASE,
} from 'src/components/header/constants';
import { pathConfig } from 'src/components/header/pathConfig';
import { LinkButton } from 'src/elements/buttons';
import { ConfirmModal } from '../modals/confirmModal';
import { useUploadData } from 'src/Logic/hooks/useUploadData';
import { UseQueryResult } from 'react-query';
import { QueryKeys } from 'src/init/reactQuery';
import { VenuesResponseType } from 'src/Logic/API/Venue/getVenues';
import { useProfileQuery } from 'src/Logic/hooks/profile/useProfileQuery';
import { useSignOutQuery } from 'src/Logic/hooks/profile/useSignOutQuery';
import * as s from './styles';

const DEFAULT_TITLE = '15SOF Upload Portal';

export const Header = () => {
    
    const [ signOutModalVisible, showSignOutModal ] = useState( false ); 
    const [ isGoBackButton, setIsGoBackButton ] = useState( false );
    const [ title, setTitle ] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { getProfileData, profileData } = useProfileQuery();
    const { 
        signOut, 
        isSuccess: signOutSuccess, 
        signOutLoading,
        queryClient,
    } = useSignOutQuery();
    const data = queryClient.getQueryData<UseQueryResult<VenuesResponseType>>( QueryKeys.Venues );
    const { venueId } = useUploadData();
    
    useEffect(() => {
        signOutSuccess && showSignOutModal( false );
    }, [ signOutSuccess ])

    useEffect(() => {
        const excludedPaths = [
            PHONE_NUMBER,
            VARIFICATION_CODE,
            NOT_FOUND,
            BASE,
        ];
        if( !profileData && !excludedPaths.includes( location.pathname )) {
            getProfileData();
        }
        if( profileData && excludedPaths.includes( location.pathname )) {
            queryClient.resetQueries( QueryKeys.ProfileData );
        }

     }, [ profileData, location.pathname, queryClient, getProfileData ]);

    useEffect(() => {
        const pathsWithBackButton = [ SELECT_VENUE_EVENT ];
        const isGoBackButton = pathsWithBackButton.includes(location.pathname);
        setIsGoBackButton( isGoBackButton )
    }, [ location.pathname ])

    useEffect(() => {
        if( data?.data && venueId !== null ){
            const pathsWithTitle = [ SELECT_VENUE_EVENT, UPLOAD_PHOTO ];
            if( pathsWithTitle.includes( location.pathname )) {
                const { data: { venues }} = data;
                const findedVenue = venues.find(( venue ) => venue.id === venueId);
                setTitle( findedVenue ? `${ findedVenue.name } (${ findedVenue.city }, 
                    ${ findedVenue.state }, ${ findedVenue.countryCode })` : '');
            }
            else
                setTitle( DEFAULT_TITLE );
        } else 
            setTitle( DEFAULT_TITLE );

    }, [ data, venueId, location.pathname ])

    const currentLocation = location.pathname;
    
    const backButtonClick = () => {
        if ( pathConfig.hasOwnProperty( currentLocation )) {
            navigate(pathConfig[ currentLocation ].redirectLink );
            return;
        }
            navigate(PREV_PAGE);
    };

    return (
        <s.Container>
            <div className = 'leftSideContainer'>
            { isGoBackButton ? (
                <BackArrow  onClick ={ backButtonClick }  className = 'mlMain goBackHeaderButton' />
            ) : (
                <PhotoEngineLogo className = {`photoUploadLogo`} />
            )}
            </div>
            <span className = 'title' >{ title }</span>
            { profileData ? 
                <s.ProfileBox  > 
                    <div className = 'userInfo' >
                        <b>{ profileData.firstName } { profileData.lastName }</b>
                        <span>Account ID { profileData.id }</span>
                    </div>
                    <div className = 'userInfoDivider' />
                    <LinkButton onClick = {() => { showSignOutModal( true ); }} className = 'sOutButton' >
                        Sign Out
                    </LinkButton>
                </s.ProfileBox>
                :
                <div style = {{ width: '165px' }} />
            }
        <ConfirmModal 
            processing = { signOutLoading }
            visible = { signOutModalVisible } 
            title = 'are you sure you want to sign out?'
            onOk = { signOut }
            onCancel = {() => { showSignOutModal( false ); }}
        />
        </s.Container>
    );
};
