import { BaseModal, BaseModalProps } from "src/components/modals/baseModal"
import { PrimaryBtn, SecondaryBtn } from "src/elements/buttons";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
    button {
        text-transform: capitalize;
        width: 150px;
    }
`;

type Props = BaseModalProps & {
    onOk?: () => void,
    onCancel?: () => void,
    confirmText?: string | undefined,
    cancelText?: string | undefined,
    title?: string | undefined,
    processing?: boolean,
}

export const ConfirmModal = ({ onCancel, onOk, cancelText, confirmText, processing, title, ...rest }: Props) => {

    const hanldeCancel = () => {
        onCancel && !processing && onCancel();
    }
    
    return (
        <BaseModal { ...rest } onCancel = { hanldeCancel } title = { title ? title : 'Are you sure?'}>
            <ButtonsWrapper className = 'd-flex'>
                <SecondaryBtn 
                    showSpinner = { processing }
                    className = 'mrMain deleteModalButton' 
                    onClick = { onOk }>{ confirmText ? confirmText : 'Yes'}</SecondaryBtn>
                <PrimaryBtn
                    className = 'deleteModalButton' 
                    onClick = { hanldeCancel } 
                    disabled = { processing }
                >{ cancelText ? cancelText : 'No' }</PrimaryBtn>
            </ButtonsWrapper>
        </BaseModal>
    )
}