export const SOFTheme = {
 sizes: {
        font: {
            main: '12px',
            large: '16px',
        },
        width: {
            mainPage: '1174px',
        },
        header: { 
            height: '67px',
        },
    },
    margins: {
        small:   '10px',
        main:  '20px',
        large: '30px',
        extraLarge:   '40px',
    },
    paddings: {
        small:   '10px',
        main:  '20px',
        large: '30px',
        extraLarge:   '40px',
    },
    colors: {
        main: '#242A38',
        gray: {
            main: '#D1D1D1',
            dark: '#979797',
            light: '#EBEBEB',
            text: '#636971',
            scrollBar: '#C4C4C4',
        },
        blue: {
            main: '#3B7B9C',
            input: '#158FFF',
            shadow: 'rgba(21, 143, 255, 0.3)',
        },
        green:{
            main: '#38C9BA',
            shadow: 'rgb(56, 201, 186, 0.3)'
        },
        red: {
            main: '#EB5757',
            shadow: 'rgb(235, 87, 87, 0.3)',
        },
        yellow: {
            main: '#FFEE95',
        },
        white: {
            main: '#FFFFFF',
        },
  },
};
