import API from 'src/Logic/API/API';
import { ErrorResponse } from 'src/Logic/models/Response/ErrorResponse';
import { ProfileDataType } from 'src/Logic/models/Response/Profile/ProfileData';

export const getProfileData = () => {
  return API.get<ProfileDataResponseType>(`api/profile`);
};

export type ProfileDataResponseType = ErrorResponse & {
    account: ProfileDataType,
}