import styled from 'styled-components';

export const Main = styled('div')`
  font-family: sans-serif;
`;

export const Container = styled('div')`
  margin: 0 auto;
  width: 360px;

  .flagIcon {
    width: 33px;
    height: 22px;
    border-radius: 4px;
    margin-right: 14px;
  }

  .downArrow {
    margin-left: 2px;
  }

  .phoneInputError {
    border: none;
    outline: 2px solid ${props => props.theme.colors.red.main};
  }
`;

export const DropDownListContainerWrapper = styled(`div`)`
  background-color: ${props => props.theme.colors.white.main};
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const SelectInputContainer = styled('div')`
  display: flex;
  border: 1px solid ${ p => p.theme.colors.gray.dark };
  border-radius: 4px;
  padding: 9px 12px;
  position: relative;

  &.focused {
    border-color: ${ p => p.theme.colors.blue.input };
    box-shadow: 0px 0px 6px ${ p => p.theme.colors.blue.shadow };
  }
`;

export const Input = styled('input')`
  border: none;
  padding: 0;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  top: 7px;
  left: 12px;

  &:focus {
    outline: none;
  }
`;

export const Placeholder = styled('span')`
  position: absolute;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  color: ${ p => p.theme.colors.gray.dark };
  letter-spacing: 0.01em;
  top: 0;
  margin-top: 8px;
  left: 94px;
`;

export const DropDownHeader = styled('div')`
  padding-right: 18px;
  display: flex;
  cursor: pointer;
`;

export const DropDownListContainer = styled('div')`
  max-height: 250px;
  border-radius: 4px;
  padding-top: 10px;
  overflow-y: scroll;
  margin-right: 3px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.colors.gray.backgroundMain};
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.gray.dark};
    border-radius: 10px;
  }
`;

export const DropDownList = styled('ul')`
  margin: 0;
  padding-left: 12px;

  li {
    cursor: pointer;
  }

  li:hover {
    background-color: ${props => props.theme.colors.gray.backgroundMain};
    color: #ffffff;
  }
`;

export const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .countryData {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${ p => p.theme.colors.main };
    letter-spacing: 0.01em;
  }
`;
