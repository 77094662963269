import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { detectBrowser } from "src/Logic/Helpers/browserDetector";
import { Bg, Header, Modal, ModalPlacer, ModalWrapper } from "./styles";

export type BaseModalProps = {
    children?: any
    className?: string,
    style?: React.CSSProperties,
    title?: string,
    headerClassName?: string,
    headerStyle?: React.CSSProperties,
    onCancel?: () => void,
    visible?: boolean, 
}

export const BaseModal = ({className, children, style, title, headerClassName, headerStyle, onCancel, visible } : BaseModalProps) => {

    const placerRef = useRef<HTMLDivElement>(null); 
    const [ screenOverflow, setScreenOverflow ] = useState( false );

    useEffect(() => {
        if( visible ) {
            if( document.body.scrollHeight > window.innerHeight ) {
                const browserName = detectBrowser();
                document.body.style.overflow = 'hidden';
                if( browserName === 'edge')
                document.body.style.paddingRight = '11px';
                else
                document.body.style.paddingRight = '18px';
            }
            
            return () => { document.body.style.overflow = 'auto'; document.body.style.paddingRight = '0px';}
        }
    }, [ visible ])

    useLayoutEffect(() => {
        if( placerRef.current && placerRef.current.offsetHeight >= window.innerHeight )
            setScreenOverflow( true );
        else if ( screenOverflow )
            setScreenOverflow( false );
     }, [ placerRef.current, children, visible ])

    return visible ? (
        <ModalWrapper role = 'document' >
        <Bg onClick = { onCancel } />
            <ModalPlacer ref = { placerRef } screenOverflow = { screenOverflow } >
                <Modal className = {className} style={style} > 
                {title !== undefined ? <Header className = {`baseModalHeader ${headerClassName}`} style = { headerStyle } >{ title }</Header> : null }
                    { children }
                </Modal>
            </ModalPlacer>
        </ModalWrapper>
    )
    :
    null;
}