
export enum FileStatuses {  
    Waiting = 'waiting',
    Uploading = 'uploading',
    Uploaded = 'uploaded',
    Failed = 'failed',
    Stopped = 'stopped',
};

export enum FileExtensions {
    png = 'image/png',
    jpeg = 'image/jpeg',
};

export const ALLOWED_EXTENSION = [
    FileExtensions.jpeg,
    FileExtensions.png,
];
export type FileDataType = {
    file: File;
    id: number,
    status: FileStatuses;
    hash?: number,
};