import { Reducer } from "redux";
import { TogglersActionTypes } from "../actions/togglers/types";

export type TechnicalErrorStateType = {
    isError: boolean,
    title?: string | undefined,
    message?: string | undefined,
}

type StateType = {
    technicalError: TechnicalErrorStateType,
}

const initialState:StateType = {
    technicalError: {
        isError: false,
    },
};

export const togglersReducer: Reducer<StateType, TogglersActionTypes> = (prevState = initialState, action) => {
        switch (action.type) {
        case 'SET_TECHNICAL_ERROR': 
        {
            return {...prevState, technicalError: { ...action.payload }};
        }
        default: {
            return prevState
        }
    }
}
