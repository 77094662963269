import styled from 'styled-components';
import React from 'react';

export const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  //max-width: 270px;

  .errorLogo {
    margin: 0 auto;
    width: 200px;
  }

  .errorText {
    margin-top: 58px;
    text-align: center;
  }
`;
type CustomErrorComponentPropsType = {
  image: string;
};
export const CustomErrorComponent: React.FC<CustomErrorComponentPropsType> = ({
  image,
  children
}) => {
  return (
    <Div>
      <img src={image} alt="" className={`errorLogo`} />
      <div className={`errorText bold-text`}>{children}</div>
    </Div>
  );
};
