import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  margin-top: ${ p => p.theme.margins.extraLarge };
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .titleName {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 60px;
  }
  
  .loadingText {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 60px;
  }
`;
